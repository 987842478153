.container {
  background-image: url(../assets/vector-autumn-foliage/lol-90cb26c2-6e30-4b68-a69b-aa969f7bd022.jpg);
  background-size: auto;
  background-repeat: none;
  position: fixed;
  top: 0px;
  right: 0rem;
  height: 100%;
}
.hide {
  background-color: var(--dark-background-color);
  color: var(--dark-text-color);
  text-align: center;
  position: fixed;
  top: 0px;
  right: 1rem;
  z-index: 1000;
  cursor: pointer;
}
.supportContent {
  background-color: var(--dark-modal-background);
  height: 100%;
  padding-top: 2rem;
  overflow-y: auto;
}
.content {
  min-height: 15em;
  border-radius: 1rem;
  padding: 1.5rem;
  color: var(--dark-text-color);
}
.content h1,
h2 {
  color: var(--dark-text-color) !important;
}
.docs {
  background-color: var(--dark-modal-background);
  color: var(--dark-text-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
@media (min-width: 1100px) {
  .container {
    width: 22rem;
  }
  .content {
    font-size: 1rem;
  }
}
