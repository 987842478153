@import "../../index.less";

.everything {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;

  text-align: center;
  /* background-image: url('../assets/background2.jpg'); */
  // background: linear-gradient(135deg, var(--dark-gradient-1) 0%, var(--dark-gradient-2) 100%);
  /* background: linear-gradient(90deg, rgba(131,58,180,1) 2%, rgba(253,29,9,1) 71%, rgba(252,176,69,1) 100%); */
  /* background: radial-gradient(circle, rgba(195,34,145,1) 0%, rgba(253,114,45,1) 100%); */
  background-image: url(../../assets/login-page/lol-20020e97-e56d-4ce9-b370-d0583dac0885.jpg);

  background-size: 100% 100%;

  padding: 0px;

  font-size: 1rem;
  color: var(--dark-text-color);
}

@media (min-width: 1100px) {
  .something {
    background-color: var(--dark-background-color);
    // NOTE: remove the halo effect
    // border-radius: 30px;
    height: calc(100vh - 0px);
    overflow: hidden;
  }

  .content {
    margin-right: 50px;
  }

  .fullWidth>div {
    width: calc(100% - 28rem);
  }
}

@media (min-width: 481px) and (max-width: 1100px) {
  .something {
    background-color: var(--dark-background-color);
    // border-radius: 30px;
    height: calc(100vh - 0px);
    overflow: hidden;
  }

  .content {
    margin-right: 20px;
  }
}

@media (max-width: 481px) {
  .everything {
    padding: 0px;
  }

  .something {
    background-color: var(--dark-background-color);
    // border-radius: 20px;
    height: calc(100vh - 0px);
    overflow-y: auto;
  }
}

