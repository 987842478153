.container {
  height: 80%;
  overflow-y: auto;
}
.card {
  background-color: transparent;
  background-color: var(--dark-modal-background);
  height: 85vh;
  width: 100%;
  margin-top: 0.5rem;
}
.card h2,
div,
a,
a:hover,
strong {
  color: var(--dark-text-color);
}
.cardHeader {
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: var(--dark-table-hover);
}
.cardContent {
  height: calc(100% - 3rem);
  overflow-y: auto;
  padding: 0;
}
.servicesHeader {
  background-color: var(--dark-table-hover);
  padding: 0.5rem;
}
.servicesList {
  font-size: 0.9rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: capitalize;
}
.servicesList:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
.servicesList:hover {
  background-color: var(--dark-table-hover);
}
.servicesList button {
  background-color: var(--dark-modal-background);
  color: var(--dark-text-color);
  width: 100%;
}
.servicesList button:hover {
  background-color: var(--dark-table-hover);
}
.servicesList button:disabled {
  background-color: var(--dark-modal-background);
  color: var(--dark-text-color);
}
@media (min-width: 1100px) {
  .container {
    left: 25rem;
    position: absolute;
    width: calc(100% - 50rem);
    margin-top: 3rem;
    height: calc(100% - 6rem);
  }
}
