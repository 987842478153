.container {
    height: 100%;
    width: 100vw !important;
    overflow-y: auto;
    background-color: #151525;
    position: fixed;
    z-index: 1001;
}

.header {
    padding-top: 2rem;
}

.logo img {
    padding: 1rem;
}

.headerElements {
    padding: 0;
    margin: 0;
}

.headerElement {
    height: 5rem !important;
    width: 100%;
    color: white;
    font-size: 1.5rem;
    border-radius: 0.5rem;
    background-color: #181a27;
    cursor: pointer;
}

.headerElement:hover {
    background-color: #202334;
}

.headerElementRegister {
    height: 5rem !important;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #d42379;
    cursor: pointer;
}

.headerElementRegister a {
    color: white;
    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow:
        1px 1px 0 #000, /* Right bottom shadow */
        2px 2px 0 #000, /* More pronounced right bottom shadow */
        3px 3px 0 #000, /* Even more pronounced right bottom shadow */
        4px 4px 0 #000; /* Maximum pronounced right bottom shadow */
}

.headerElementRegister:hover {
    background-color: #b4035b;
}

.login {
    position: fixed;
    right: 0;
    top: 0;
}

.banner {
    background-image: url(../../assets/login7.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.heroheaderbanner {
    text-align: center;
    margin-top: 3vh;
    // margin-left: 35vw;
}

.heroheaderbanner h1 {
    font-size: 6rem;
    font-weight: 900;
    color: yellow;

    text-transform: capitalize;
    text-shadow:
        6px 6px 0 #000, /* Shadow right and bottom */
        -1px -1px 0 #000, /* Shadow top and left */
        1px -1px 0 #000, /* Shadow top and right */
        -1px 1px 0 #000, /* Shadow bottom and left */
        0 0 5px #000, /* Soft outer glow for depth */
        8px 8px 0 #000, /* Additional layer for 3D effect */
        10px 10px 0 #000; /* Additional layer for 3D effect */
}

.heroheaderbanner h2, h3 {
    font-size: 4rem;
    font-weight: 900;
    color: white;

    text-transform: capitalize;
    text-shadow:
        2px 2px 0 #000, /* Right bottom shadow */
        4px 4px 0 #000, /* More pronounced right bottom shadow */
        6px 6px 0 #000, /* Even more pronounced right bottom shadow */
        8px 8px 0 #000; /* Maximum pronounced right bottom shadow */
}

.heroheaderbanner ul {
    margin-top: 4vh;
    margin-left: 10vw;
    margin-right: 10vw;
}

.heroheaderbanner li {
    padding: 1rem;
    margin: 2rem;

    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    text-align: center;

    border: 10px solid yellow;
    background-color: rgba(0,0,0,0.7);
}

.heroTasks {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 2rem;
}

.heroTask {
    color: white;
    border: 8px solid #d42379;
    border-radius: 1rem;

    padding: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.2rem;
    background-color: rgba(30, 0, 0, 0.2);
}

.heroTask h1 {
    color: white;
    font-weight: 900;
    text-align: center;
}

.heroTask p {
    text-align: center;
}

.gridElement {
    color: white;
    font-size: 1rem;
    height: 6rem !important;
    width: 100%;
    background-color: #1d1c30;
    text-align: center;
}

.gridElementYour {
    color: white;
    font-size: 1rem;
    height: 6rem !important;
    width: 100%;
    background-color: #5b223e;
    text-align: center;
}

.gridElement a {
    width: 100%;
    margin: auto;
    text-align: center;
}

.gridElement:hover {
    background-color: #1d1c3090;
}

.gridElementYour:hover {
    background-color: #5b223e90;
}

.herofooterbanner {
    margin-top: 2rem;
    text-align: center;
    font-size: 2rem;
}

.herofooterbanner h1 {
    font-size: 6rem;
    font-weight: 900;
    color: yellow;

    text-transform: capitalize;
    text-shadow:
        6px 6px 0 #000, /* Shadow right and bottom */
        -1px -1px 0 #000, /* Shadow top and left */
        1px -1px 0 #000, /* Shadow top and right */
        -1px 1px 0 #000, /* Shadow bottom and left */
        0 0 5px #000, /* Soft outer glow for depth */
        8px 8px 0 #000, /* Additional layer for 3D effect */
        10px 10px 0 #000; /* Additional layer for 3D effect */
}

.footer {
    margin-top: 3rem;
    padding-top: 10vh;
    background-image: url(../../assets/banner5.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
}

.footer a {
    font-size: 2rem;
    font-weight: 900;
    color: white;

    text-transform: capitalize;
    text-shadow:
        2px 2px 0 #000, /* Right bottom shadow */
        4px 4px 0 #000, /* More pronounced right bottom shadow */
        6px 6px 0 #000, /* Even more pronounced right bottom shadow */
        8px 8px 0 #000; /* Maximum pronounced right bottom shadow */
}

.footerGrid > div {
    margin: 1rem;
}

@media (min-width: 1600px) {}

@media (min-width: 481px) and (max-width: 1600px) {
    .heroheaderbanner h1 {
        font-size: 6rem;
    }

    .heroheaderbanner h2, h3 {
        font-size: 4rem;
    }

    .heroheaderbanner ul {
        margin-top: 0vh;
        margin-left: 0vw;
        margin-right: 0vw;
    }

    .heroheaderbanner li {
        padding: 1rem;
        margin: 3rem;

        font-size: 2rem;
    }

    .heroTasks {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 2rem;
    }

    .heroTask {
        color: white;
        border-radius: 1rem;

        padding: 3rem;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1.2rem;
    }

    .gridElement {
        color: white;
        font-size: 1rem;
        height: 6rem !important;
        text-align: center;
    }

    .gridElementYour {
        color: white;
        font-size: 1rem;
        height: 6rem !important;
        text-align: center;
    }
}

@media (max-width: 481px) {
    .headerElementRegister {
        height: 2.5rem !important;
        width: 100%;
        border-radius: 0.5rem;
        background-color: #d42379;
        cursor: pointer;
    }

    .headerElementRegister a {
        font-size: 1rem;
        text-shadow: none;
    }

    .heroheaderbanner h1 {
        font-size: 2rem;
    }

    .heroheaderbanner h2, h3 {
        font-size: 1rem;
    }

    .heroheaderbanner ul {
        margin-top: 0vh;
        margin-left: 0vw;
        margin-right: 0vw;
    }

    .heroheaderbanner li {
        padding: 0.5rem;
        margin: 1rem;

        font-size: 1rem;
    }

    .heroTasks {
        margin-left: 0rem;
        margin-right: 0rem;
        margin-top: 2rem;
    }

    .heroTask {
        color: white;
        border-radius: 1rem;

        padding: 3rem;
        margin-left: 0rem;
        margin-right: 0rem;
        font-size: 1.2rem;
    }

    .gridElement {
        color: white;
        font-size: 1rem;
        height: 6rem !important;
        text-align: center;
    }

    .gridElementYour {
        color: white;
        font-size: 1rem;
        height: 6rem !important;
        text-align: center;
    }

    .footer {
        height: 100vh;
    }

    .footer a {
        font-size: 1rem;
        text-shadow:
            1px 1px 0 #000, /* Right bottom shadow */
            2px 2px 0 #000, /* More pronounced right bottom shadow */
            3px 3px 0 #000, /* Even more pronounced right bottom shadow */
            4px 4px 0 #000; /* Maximum pronounced right bottom shadow */
    }
}

