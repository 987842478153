.container {
    height: 80%;
    overflow-y: auto;
    padding: 1rem;
}

.searchContainer {
    margin-bottom: 2em;
    background-color: var(--dark-table-head);
    border-radius: 0.5rem;
    // padding: 0.5em;
}

.searchInput {
    background-color: rgba(0,0,0,0.1);
    color: var(--dark-text-color);
    border: none;
    padding: 0.5rem;
}

.searchInput::placeholder {
    color: var(--dark-text-color);
}

.taskGrid {
    overflow: none;
}

.modelCard {
    background-size: contain;
}

.modelCard li {
    text-align: left;
}

.card {
    height: 100%;
    background-color: transparent;
    background-color: var(--dark-modal-background);
    padding: 10px;
}
.card h3, div, a, a:hover, strong {
    color: var(--dark-text-color);
    text-shadow: none;
}

.cardHeader {
    // border: none !important;
    height: 3rem;
    border: 4px solid var(--dark-background-color);
}

.cardHeader > div {
    background-color: var(--dark-modal-background);
}

.cardContent {
    padding: 0.5rem;
}

.cardContent p {
    font-size: 0.9rem;
}

.cardFooter {
    margin-top: 0.6rem;
    height: 1rem;
}

.cardFooter div {
    padding: 0;
}

.cardFooter div:hover {
    background-color: var(--dark-background-histogram);
}

.cardFooter a {
    text-align: center;
    padding: 0.7rem;
    height: 100%;
    width: 100%;
}

@media (min-width: 1100px) {
    .container {
        left: 25rem;
        position: absolute;
        width: calc(100% - 50rem);
        margin-top: 3rem;
        height: calc(100% - 6rem);
    }
}

@media (min-width: 481px) and (max-width: 1100px) {

}

@media (max-width: 481px) {

}
