.container {
    height: 80%;
    overflow-y: auto;
    background-color: var(--dark-table-head);
    border-radius: 1rem;
}

.headerImage {
    height: 8rem;
    margin-bottom: 1.5rem;

    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
}

.contentHeading {
    font-size: 1rem;
}

.contentHeading h2 {
    color: var(--dark-text-color);
}

.form {
    width: 100%;
    font-size: 1.1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.textInput {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5rem;

    background-color: var(--dark-table-head);
    color: var(--dark-text-color);
    border: none;
}

.numberInput {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5rem;

    background-color: var(--dark-table-head);
    color: var(--dark-text-color);
    border: none;
}

.checkboxInput {
    margin-right: 0.5rem;

    background-color: var(--dark-table-head);
    color: var(--dark-text-color);
    accent-color: var(--dark-neon-color-2);
    border: none;
    height: 30px;
}

.selectInput {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5rem;

    background-color: var(--dark-table-head);
    color: var(--dark-text-color);
    border: none;
    width: 100%;
}

option {
    font-size: 1.1em;

    background-color: var(--dark-background-color);
    color: var(--dark-text-color);
    width: 100%;
}

.formElement {
    width: 80%;
}

.formElement input {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    font-size: 1.3rem;
}

.formElement label {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    align-items: center;
    cursor: pointer;
}

.action {
    margin-top: 1rem;
}

.action button {
    width: 100%;
    background-color: var(--dark-modal-background);
    color: var(--dark-text-color);
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.action button:disabled {
  background-color: var(--dark-modal-background);
  color: var(--dark-text-color);
}

.progressBar {
    width: 100%;
    // background-color: #e0e0e0;
    border-radius: 5px;
}

.progress {
    height: 3rem;
    background: var(--dark-background-histogram2);
    border-radius: 5px;
    transition: width 1.2s ease;
}

.curl {
    margin-top: 1.5rem;
    width: 100%;
    font-size: 1rem;
}

.curl h3 {
    color: var(--dark-text-color);
}

.curl pre {
    background-color: var(--dark-modal-background);
    font-size: 1rem;
}

@media (min-width: 1100px) {
    .container {
        left: 25rem;
        position: absolute;
        width: calc(100% - 50rem);
        margin-top: 3rem;
        height: calc(100% - 6rem);
        padding: 5rem;
    }
}

@media (min-width: 481px) and (max-width: 1100px) {}

@media (max-width: 481px) {}

@keyframes backgroundAnimation {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 150%; }
}
