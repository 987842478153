:root,
[data-amplify-theme] {
    --amplify-transforms-slide-x-large: translateX(2em);
    --amplify-transforms-slide-x-medium: translateX(1em);
    --amplify-transforms-slide-x-small: translateX(.5em);
    --amplify-time-long: .5s;
    --amplify-time-medium: .25s;
    --amplify-time-short: .1s;
    --amplify-space-relative-xxxl: 4.5em;
    --amplify-space-relative-xxl: 3em;
    --amplify-space-relative-xl: 2em;
    --amplify-space-relative-large: 1.5em;
    --amplify-space-relative-medium: 1em;
    --amplify-space-relative-small: .75em;
    --amplify-space-relative-xs: .5em;
    --amplify-space-relative-xxs: .375rem;
    --amplify-space-relative-xxxs: .25em;
    --amplify-space-xxxl: 4.5rem;
    --amplify-space-xxl: 3rem;
    --amplify-space-xl: 2rem;
    --amplify-space-large: 1.5rem;
    --amplify-space-medium: 1rem;
    --amplify-space-small: .75rem;
    --amplify-space-xs: .5rem;
    --amplify-space-xxs: .375rem;
    --amplify-space-xxxs: .25rem;
    --amplify-shadows-large: 0px 4px 12px var(--amplify-colors-shadow-primary);
    --amplify-shadows-medium: 0px 2px 6px var(--amplify-colors-shadow-secondary);
    --amplify-shadows-small: 0px 2px 4px var(--amplify-colors-shadow-tertiary);
    --amplify-radii-xxxl: 8rem;
    --amplify-radii-xxl: 4rem;
    --amplify-radii-xl: 2rem;
    --amplify-radii-large: 1rem;
    --amplify-radii-medium: .5rem;
    --amplify-radii-small: .25rem;
    --amplify-radii-xs: .125rem;
    --amplify-outline-widths-large: 3px;
    --amplify-outline-widths-medium: 2px;
    --amplify-outline-widths-small: 1px;
    --amplify-outline-offsets-large: 3px;
    --amplify-outline-offsets-medium: 2px;
    --amplify-outline-offsets-small: 1px;
    --amplify-opacities-100: 1;
    --amplify-opacities-90: .9;
    --amplify-opacities-80: .8;
    --amplify-opacities-70: .7;
    --amplify-opacities-60: .6;
    --amplify-opacities-50: .5;
    --amplify-opacities-40: .4;
    --amplify-opacities-30: .3;
    --amplify-opacities-20: .2;
    --amplify-opacities-10: .1;
    --amplify-opacities-0: 0;
    --amplify-line-heights-large: 2;
    --amplify-line-heights-medium: 1.75;
    --amplify-line-heights-small: 1.5;
    --amplify-font-weights-black: 900;
    --amplify-font-weights-extrabold: 800;
    --amplify-font-weights-bold: 700;
    --amplify-font-weights-semibold: 600;
    --amplify-font-weights-medium: 500;
    --amplify-font-weights-normal: 400;
    --amplify-font-weights-light: 300;
    --amplify-font-weights-thin: 200;
    --amplify-font-weights-hairline: 100;
    --amplify-font-sizes-xxxxl: 5rem;
    --amplify-font-sizes-xxxl: 3rem;
    --amplify-font-sizes-xxl: 2rem;
    --amplify-font-sizes-xl: 1.5rem;
    --amplify-font-sizes-large: 1.25rem;
    --amplify-font-sizes-medium: 1rem;
    --amplify-font-sizes-small: .875rem;
    --amplify-font-sizes-xs: .75rem;
    --amplify-font-sizes-xxs: .5rem;
    --amplify-font-sizes-xxxs: .375rem;
    --amplify-fonts-default-static: "Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
    --amplify-fonts-default-variable: "InterVariable", "Inter var", "Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
    --amplify-colors-transparent: transparent;
    --amplify-colors-white: hsl(0, 0%, 100%);
    --amplify-colors-black: hsl(0, 0%, 0%);
    --amplify-colors-overlay-90: hsla(0, 0%, 0%, .9);
    --amplify-colors-overlay-80: hsla(0, 0%, 0%, .8);
    --amplify-colors-overlay-70: hsla(0, 0%, 0%, .7);
    --amplify-colors-overlay-60: hsla(0, 0%, 0%, .6);
    --amplify-colors-overlay-50: hsla(0, 0%, 0%, .5);
    --amplify-colors-overlay-40: hsla(0, 0%, 0%, .4);
    --amplify-colors-overlay-30: hsla(0, 0%, 0%, .3);
    --amplify-colors-overlay-20: hsla(0, 0%, 0%, .2);
    --amplify-colors-overlay-10: hsla(0, 0%, 0%, .1);
    --amplify-colors-shadow-tertiary: hsla(210, 50%, 10%, .05);
    --amplify-colors-shadow-secondary: hsla(210, 50%, 10%, .15);
    --amplify-colors-shadow-primary: hsla(210, 50%, 10%, .25);
    --amplify-colors-border-error: var(--amplify-colors-red-80);
    --amplify-colors-border-focus: var(--amplify-colors-brand-primary-100);
    --amplify-colors-border-disabled: var(--amplify-colors-border-tertiary);
    --amplify-colors-border-tertiary: var(--amplify-colors-neutral-20);
    --amplify-colors-border-secondary: var(--amplify-colors-neutral-40);
    --amplify-colors-border-primary: var(--amplify-colors-neutral-60);
    --amplify-colors-background-success: var(--amplify-colors-green-20);
    --amplify-colors-background-error: var(--amplify-colors-red-20);
    --amplify-colors-background-warning: var(--amplify-colors-orange-20);
    --amplify-colors-background-info: var(--amplify-colors-blue-20);
    --amplify-colors-background-disabled: var(--amplify-colors-background-tertiary);
    --amplify-colors-background-tertiary: var(--amplify-colors-neutral-20);
    --amplify-colors-background-secondary: hsla(39, 88%, 73%, 20%);
    --amplify-colors-background-primary: white;
    --amplify-colors-font-success: var(--amplify-colors-green-90);
    --amplify-colors-font-error: var(--amplify-colors-red-90);
    --amplify-colors-font-warning: var(--amplify-colors-orange-90);
    --amplify-colors-font-info: var(--amplify-colors-blue-90);
    --amplify-colors-font-active: var(--amplify-colors-brand-primary-100);
    --amplify-colors-font-focus: var(--amplify-colors-brand-primary-100);
    --amplify-colors-font-hover: var(--amplify-colors-brand-primary-90);
    --amplify-colors-font-interactive: var(--amplify-colors-brand-primary-80);
    --amplify-colors-font-inverse: var(--amplify-colors-white);
    --amplify-colors-font-disabled: var(--amplify-colors-font-tertiary);
    --amplify-colors-font-tertiary: var(--amplify-colors-neutral-80);
    --amplify-colors-font-secondary: var(--amplify-colors-neutral-90);
    --amplify-colors-font-primary: var(--amplify-colors-neutral-100);
    --amplify-colors-brand-secondary-100: var(--amplify-colors-purple-100);
    --amplify-colors-brand-secondary-90: var(--amplify-colors-purple-90);
    --amplify-colors-brand-secondary-80: var(--amplify-colors-purple-80);
    --amplify-colors-brand-secondary-60: var(--amplify-colors-purple-60);
    --amplify-colors-brand-secondary-40: var(--amplify-colors-purple-40);
    --amplify-colors-brand-secondary-20: var(--amplify-colors-purple-20);
    --amplify-colors-brand-secondary-10: var(--amplify-colors-purple-10);
    --amplify-colors-brand-primary-100: var(--amplify-colors-pink-100);
    --amplify-colors-brand-primary-90: var(--amplify-colors-pink-90);
    --amplify-colors-brand-primary-80: var(--amplify-colors-pink-80);
    --amplify-colors-brand-primary-60: var(--amplify-colors-pink-60);
    --amplify-colors-brand-primary-40: var(--amplify-colors-pink-40);
    --amplify-colors-brand-primary-20: var(--amplify-colors-pink-20);
    --amplify-colors-brand-primary-10: var(--amplify-colors-pink-10);
    --amplify-colors-neutral-100: hsl(210, 50%, 10%);
    --amplify-colors-neutral-90: hsl(210, 25%, 25%);
    --amplify-colors-neutral-80: hsl(210, 10%, 40%);
    --amplify-colors-neutral-60: hsl(210, 6%, 70%);
    --amplify-colors-neutral-40: hsl(210, 5%, 87%);
    --amplify-colors-neutral-20: hsl(210, 5%, 94%);
    --amplify-colors-neutral-10: hsl(210, 5%, 98%);
    --amplify-colors-pink-100: hsl(340, 100%, 15%);
    --amplify-colors-pink-90: hsl(340, 100%, 20%);
    --amplify-colors-pink-80: hsl(340, 95%, 30%);
    --amplify-colors-pink-60: hsl(340, 50%, 50%);
    --amplify-colors-pink-40: hsl(340, 70%, 70%);
    --amplify-colors-pink-20: hsl(340, 90%, 85%);
    --amplify-colors-pink-10: hsl(340, 95%, 95%);
    --amplify-colors-purple-100: hsl(300, 100%, 15%);
    --amplify-colors-purple-90: hsl(300, 100%, 20%);
    --amplify-colors-purple-80: hsl(300, 95%, 30%);
    --amplify-colors-purple-60: hsl(300, 50%, 50%);
    --amplify-colors-purple-40: hsl(300, 70%, 70%);
    --amplify-colors-purple-20: hsl(300, 85%, 85%);
    --amplify-colors-purple-10: hsl(300, 95%, 95%);
    --amplify-colors-blue-100: hsl(220, 100%, 15%);
    --amplify-colors-blue-90: hsl(220, 100%, 20%);
    --amplify-colors-blue-80: hsl(220, 95%, 30%);
    --amplify-colors-blue-60: hsl(220, 50%, 50%);
    --amplify-colors-blue-40: hsl(220, 70%, 70%);
    --amplify-colors-blue-20: hsl(220, 85%, 85%);
    --amplify-colors-blue-10: hsl(220, 95%, 95%);
    --amplify-colors-teal-100: hsl(39, 88%, 100%);
    --amplify-colors-teal-90: hsl(39, 88%, 90%);
    --amplify-colors-teal-80: hsl(39, 88%, 80%);
    --amplify-colors-teal-60: hsl(39, 88%, 60%);
    --amplify-colors-teal-40: hsl(39, 88%, 40%);
    --amplify-colors-teal-20: hsl(39, 88%, 20%);
    --amplify-colors-teal-10: hsl(39, 88%, 10%);
    --amplify-colors-green-100: hsl(130, 22%, 23%);
    --amplify-colors-green-90: hsl(130, 27%, 29%);
    --amplify-colors-green-80: hsl(130, 33%, 37%);
    --amplify-colors-green-60: hsl(130, 43%, 46%);
    --amplify-colors-green-40: hsl(130, 44%, 63%);
    --amplify-colors-green-20: hsl(130, 60%, 90%);
    --amplify-colors-green-10: hsl(130, 60%, 95%);
    --amplify-colors-yellow-100: hsl(60, 100%, 15%);
    --amplify-colors-yellow-90: hsl(60, 100%, 20%);
    --amplify-colors-yellow-80: hsl(60, 95%, 30%);
    --amplify-colors-yellow-60: hsl(60, 50%, 50%);
    --amplify-colors-yellow-40: hsl(60, 75%, 75%);
    --amplify-colors-yellow-20: hsl(60, 75%, 85%);
    --amplify-colors-yellow-10: hsl(60, 75%, 95%);
    --amplify-colors-orange-100: hsl(30, 100%, 15%);
    --amplify-colors-orange-90: hsl(30, 100%, 20%);
    --amplify-colors-orange-80: hsl(30, 95%, 30%);
    --amplify-colors-orange-60: hsl(30, 50%, 50%);
    --amplify-colors-orange-40: hsl(30, 75%, 75%);
    --amplify-colors-orange-20: hsl(30, 75%, 85%);
    --amplify-colors-orange-10: hsl(30, 75%, 95%);
    --amplify-colors-red-100: hsl(0, 100%, 15%);
    --amplify-colors-red-90: hsl(0, 100%, 20%);
    --amplify-colors-red-80: hsl(0, 95%, 30%);
    --amplify-colors-red-60: hsl(0, 50%, 50%);
    --amplify-colors-red-40: hsl(0, 75%, 75%);
    --amplify-colors-red-20: hsl(0, 75%, 85%);
    --amplify-colors-red-10: hsl(0, 75%, 95%);
    --amplify-border-widths-large: 3px;
    --amplify-border-widths-medium: 2px;
    --amplify-border-widths-small: 1px;
    --amplify-components-togglebuttongroup-justify-content: flex-start;
    --amplify-components-togglebuttongroup-align-content: center;
    --amplify-components-togglebuttongroup-align-items: center;
    --amplify-components-togglebutton-link-pressed-hover-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-pressed-color: var(--amplify-colors-overlay-90);
    --amplify-components-togglebutton-link-pressed-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-togglebutton-link-focus-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-link-focus-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-hover-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-link-hover-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-primary-pressed-hover-background-color: var(--amplify-colors-brand-primary-60);
    --amplify-components-togglebutton-primary-pressed-hover-border-color: var(--amplify-colors-brand-primary-60);
    --amplify-components-togglebutton-primary-pressed-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-togglebutton-primary-pressed-focus-background-color: var(--amplify-colors-border-focus);
    --amplify-components-togglebutton-primary-pressed-color: var(--amplify-colors-white);
    --amplify-components-togglebutton-primary-pressed-border-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-togglebutton-primary-pressed-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-togglebutton-primary-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-togglebutton-primary-disabled-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-togglebutton-primary-hover-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-primary-hover-background-color: var(--amplify-colors-overlay-10);
    --amplify-components-togglebutton-primary-focus-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-primary-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-togglebutton-primary-focus-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-primary-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-togglebutton-primary-border-width: var(--amplify-border-widths-small);
    --amplify-components-togglebutton-primary-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-pressed-hover-background-color: var(--amplify-colors-overlay-30);
    --amplify-components-togglebutton-pressed-background-color: var(--amplify-colors-overlay-20);
    --amplify-components-togglebutton-pressed-color: var(--amplify-colors-overlay-90);
    --amplify-components-togglebutton-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-togglebutton-disabled-border-color: var(--amplify-colors-border-secondary);
    --amplify-components-togglebutton-disabled-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-active-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-focus-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-togglebutton-hover-background-color: var(--amplify-colors-overlay-10);
    --amplify-components-togglebutton-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-border-color: var(--amplify-colors-border-primary);
    --amplify-components-text-info-color: var(--amplify-colors-font-info);
    --amplify-components-text-success-color: var(--amplify-colors-font-success);
    --amplify-components-text-warning-color: var(--amplify-colors-font-warning);
    --amplify-components-text-error-color: var(--amplify-colors-font-error);
    --amplify-components-text-tertiary-color: var(--amplify-colors-font-tertiary);
    --amplify-components-text-secondary-color: var(--amplify-colors-font-secondary);
    --amplify-components-text-primary-color: var(--amplify-colors-font-primary);
    --amplify-components-text-color: var(--amplify-colors-font-primary);
    --amplify-components-tabs-item-disabled-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-tabs-item-disabled-background-color: transparent;
    --amplify-components-tabs-item-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-tabs-item-active-background-color: transparent;
    --amplify-components-tabs-item-active-border-color: var(--amplify-colors-font-interactive);
    --amplify-components-tabs-item-active-color: var(--amplify-colors-font-interactive);
    --amplify-components-tabs-item-focus-color: var(--amplify-colors-font-focus);
    --amplify-components-tabs-item-hover-color: var(--amplify-colors-font-hover);
    --amplify-components-tabs-item-transition-duration: var(--amplify-time-medium);
    --amplify-components-tabs-item-text-align: center;
    --amplify-components-tabs-item-padding-horizontal: var(--amplify-space-medium);
    --amplify-components-tabs-item-padding-vertical: var(--amplify-space-small);
    --amplify-components-tabs-item-font-weight: var(--amplify-font-weights-bold);
    --amplify-components-tabs-item-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-tabs-item-color: var(--amplify-colors-font-secondary);
    --amplify-components-tabs-item-border-width: var(--amplify-border-widths-medium);
    --amplify-components-tabs-item-border-style: solid;
    --amplify-components-tabs-item-border-color: var(--amplify-colors-border-secondary);
    --amplify-components-tabs-item-background-color: transparent;
    --amplify-components-tabs-gap: 0;
    --amplify-components-tabs-border-width: var(--amplify-border-widths-medium);
    --amplify-components-tabs-border-style: solid;
    --amplify-components-tabs-border-color: var(--amplify-colors-border-secondary);
    --amplify-components-tabs-background-color: transparent;
    --amplify-components-table-caption-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-table-caption-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-table-caption-word-break: break-all;
    --amplify-components-table-caption-text-align: center;
    --amplify-components-table-caption-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-table-caption-display: table-caption;
    --amplify-components-table-caption-color: var(--amplify-colors-font-primary);
    --amplify-components-table-caption-caption-side: bottom;
    --amplify-components-table-data-small-padding: var(--amplify-space-xs);
    --amplify-components-table-data-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-table-data-large-padding: var(--amplify-space-large);
    --amplify-components-table-data-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-table-data-vertical-align: middle;
    --amplify-components-table-data-padding: var(--amplify-space-medium);
    --amplify-components-table-data-font-weight: var(--amplify-font-weights-normal);
    --amplify-components-table-data-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-table-data-display: table-cell;
    --amplify-components-table-data-color: var(--amplify-colors-font-primary);
    --amplify-components-table-data-border-width: var(--amplify-border-widths-small);
    --amplify-components-table-data-border-style: solid;
    --amplify-components-table-data-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-table-header-small-padding: var(--amplify-space-xs);
    --amplify-components-table-header-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-table-header-large-padding: var(--amplify-space-large);
    --amplify-components-table-header-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-table-header-vertical-align: middle;
    --amplify-components-table-header-padding: var(--amplify-space-medium);
    --amplify-components-table-header-font-weight: var(--amplify-font-weights-bold);
    --amplify-components-table-header-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-table-header-display: table-cell;
    --amplify-components-table-header-color: var(--amplify-colors-font-primary);
    --amplify-components-table-header-border-width: var(--amplify-border-widths-small);
    --amplify-components-table-header-border-style: solid;
    --amplify-components-table-header-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-table-row-striped-background-color: var(--amplify-colors-background-secondary);
    --amplify-components-table-row-hover-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-table-row-vertical-align: middle;
    --amplify-components-table-row-display: table-row;
    --amplify-components-table-foot-vertical-align: middle;
    --amplify-components-table-foot-display: table-footer-group;
    --amplify-components-table-body-vertical-align: middle;
    --amplify-components-table-body-display: table-row-group;
    --amplify-components-table-head-vertical-align: middle;
    --amplify-components-table-head-display: table-header-group;
    --amplify-components-table-width: 100%;
    --amplify-components-table-display: table;
    --amplify-components-table-border-collapse: collapse;
    --amplify-components-switchfield-track-width: var(--amplify-space-relative-xl);
    --amplify-components-switchfield-track-transition-duration: var(--amplify-time-short);
    --amplify-components-switchfield-track-padding: var(--amplify-outline-widths-medium);
    --amplify-components-switchfield-track-height: var(--amplify-space-relative-medium);
    --amplify-components-switchfield-track-checked-background-color: var(--amplify-colors-brand-primary-60);
    --amplify-components-switchfield-track-border-radius: var(--amplify-radii-xxxl);
    --amplify-components-switchfield-track-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-switchfield-thumb-width: var(--amplify-space-relative-medium);
    --amplify-components-switchfield-thumb-transition-duration: var(--amplify-time-medium);
    --amplify-components-switchfield-thumb-checked-transform: var(--amplify-transforms-slide-x-medium);
    --amplify-components-switchfield-thumb-border-radius: var(--amplify-radii-xxxl);
    --amplify-components-switchfield-thumb-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-switchfield-thumb-background-color: var(--amplify-colors-background-primary);
    --amplify-components-switchfield-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-switchfield-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-switchfield-label-padding: var(--amplify-space-xs);
    --amplify-components-switchfield-focused-shadow: var(--amplify-shadows-small);
    --amplify-components-switchfield-disabled-opacity: var(--amplify-opacities-60);
    --amplify-components-stepperfield-input-text-align: center;
    --amplify-components-stepperfield-flex-direction: column;
    --amplify-components-sliderfield-large-thumb-height: 1.5rem;
    --amplify-components-sliderfield-large-thumb-width: 1.5rem;
    --amplify-components-sliderfield-large-track-height: .625rem;
    --amplify-components-sliderfield-small-thumb-height: 1rem;
    --amplify-components-sliderfield-small-thumb-width: 1rem;
    --amplify-components-sliderfield-small-track-height: .25rem;
    --amplify-components-sliderfield-thumb-focus-box-shadow: 0 0 0 3px var(--amplify-colors-border-focus);
    --amplify-components-sliderfield-thumb-hover-border-color: var(--amplify-colors-border-focus);
    --amplify-components-sliderfield-thumb-hover-background-color: var(--amplify-colors-background-primary);
    --amplify-components-sliderfield-thumb-disabled-box-shadow: none;
    --amplify-components-sliderfield-thumb-disabled-border-color: transparent;
    --amplify-components-sliderfield-thumb-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-sliderfield-thumb-border-style: solid;
    --amplify-components-sliderfield-thumb-border-color: var(--amplify-colors-border-primary);
    --amplify-components-sliderfield-thumb-border-width: var(--amplify-border-widths-small);
    --amplify-components-sliderfield-thumb-border-radius: 50%;
    --amplify-components-sliderfield-thumb-box-shadow: var(--amplify-shadows-medium);
    --amplify-components-sliderfield-thumb-background-color: var(--amplify-colors-background-primary);
    --amplify-components-sliderfield-thumb-height: 1.25rem;
    --amplify-components-sliderfield-thumb-width: 1.25rem;
    --amplify-components-sliderfield-range-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-sliderfield-range-border-radius: 9999px;
    --amplify-components-sliderfield-range-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-sliderfield-track-min-width: 10rem;
    --amplify-components-sliderfield-track-height: .375rem;
    --amplify-components-sliderfield-track-border-radius: 9999px;
    --amplify-components-sliderfield-track-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-sliderfield-padding-block: var(--amplify-space-xs);
    --amplify-components-selectfield-flex-direction: column;
    --amplify-components-select-large-min-width: 7.5rem;
    --amplify-components-select-small-min-width: 5.5rem;
    --amplify-components-select-min-width: 6.5rem;
    --amplify-components-select-white-space: nowrap;
    --amplify-components-select-option-background-color: var(--amplify-colors-background-primary);
    --amplify-components-select-icon-wrapper-pointer-events: none;
    --amplify-components-select-icon-wrapper-transform: translateY(-50%);
    --amplify-components-select-icon-wrapper-right: var(--amplify-space-medium);
    --amplify-components-select-icon-wrapper-top: 50%;
    --amplify-components-select-icon-wrapper-position: absolute;
    --amplify-components-select-icon-wrapper-align-items: center;
    --amplify-components-select-wrapper-cursor: pointer;
    --amplify-components-select-wrapper-position: relative;
    --amplify-components-select-wrapper-display: block;
    --amplify-components-select-wrapper-flex: 1;
    --amplify-components-select-padding-inline-end: var(--amplify-space-xxl);
    --amplify-components-rating-empty-color: var(--amplify-colors-background-tertiary);
    --amplify-components-rating-filled-color: var(--amplify-colors-brand-secondary-80);
    --amplify-components-rating-small-size: var(--amplify-font-sizes-small);
    --amplify-components-rating-default-size: var(--amplify-font-sizes-xl);
    --amplify-components-rating-large-size: var(--amplify-font-sizes-xxxl);
    --amplify-components-radio-label-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-radio-button-disabled-background-color: var(--amplify-colors-background-primary);
    --amplify-components-radio-button-disabled-border-color: var(--amplify-colors-border-disabled);
    --amplify-components-radio-button-error-focus-box-shadow: var(--amplify-components-fieldcontrol-error-focus-box-shadow);
    --amplify-components-radio-button-error-border-color: var(--amplify-colors-border-error);
    --amplify-components-radio-button-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-radio-button-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-radio-button-checked-disabled-color: var(--amplify-colors-background-disabled);
    --amplify-components-radio-button-checked-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-radio-button-large-height: var(--amplify-font-sizes-large);
    --amplify-components-radio-button-large-width: var(--amplify-font-sizes-large);
    --amplify-components-radio-button-small-height: var(--amplify-font-sizes-small);
    --amplify-components-radio-button-small-width: var(--amplify-font-sizes-small);
    --amplify-components-radio-button-padding: var(--amplify-border-widths-medium);
    --amplify-components-radio-button-outline-offset: var(--amplify-outline-offsets-medium);
    --amplify-components-radio-button-outline-width: var(--amplify-outline-widths-medium);
    --amplify-components-radio-button-outline-style: solid;
    --amplify-components-radio-button-outline-color: var(--amplify-colors-transparent);
    --amplify-components-radio-button-transition-duration: var(--amplify-time-medium);
    --amplify-components-radio-button-transition-property: all;
    --amplify-components-radio-button-background-color: var(--amplify-colors-white);
    --amplify-components-radio-button-color: var(--amplify-colors-white);
    --amplify-components-radio-button-border-color: var(--amplify-colors-border-primary);
    --amplify-components-radio-button-border-radius: 50%;
    --amplify-components-radio-button-border-style: solid;
    --amplify-components-radio-button-border-width: var(--amplify-border-widths-medium);
    --amplify-components-radio-button-box-sizing: border-box;
    --amplify-components-radio-button-height: var(--amplify-font-sizes-medium);
    --amplify-components-radio-button-width: var(--amplify-font-sizes-medium);
    --amplify-components-radio-button-justify-content: center;
    --amplify-components-radio-button-align-items: center;
    --amplify-components-radio-disabled-cursor: not-allowed;
    --amplify-components-radio-gap: inherit;
    --amplify-components-radio-justify-content: flex-start;
    --amplify-components-radio-align-items: center;
    --amplify-components-placeholder-large-height: var(--amplify-space-large);
    --amplify-components-placeholder-default-height: var(--amplify-space-medium);
    --amplify-components-placeholder-small-height: var(--amplify-space-small);
    --amplify-components-placeholder-end-color: var(--amplify-colors-background-tertiary);
    --amplify-components-placeholder-start-color: var(--amplify-colors-background-secondary);
    --amplify-components-placeholder-transition-duration: var(--amplify-time-long);
    --amplify-components-placeholder-border-radius: var(--amplify-radii-small);
    --amplify-components-pagination-item-shared-border-radius: var(--amplify-font-sizes-medium);
    --amplify-components-pagination-item-shared-min-width: var(--amplify-font-sizes-xxl);
    --amplify-components-pagination-item-shared-height: var(--amplify-font-sizes-xxl);
    --amplify-components-pagination-item-container-margin-right: var(--amplify-space-xxxs);
    --amplify-components-pagination-item-container-margin-left: var(--amplify-space-xxxs);
    --amplify-components-pagination-ellipsis-padding-inline-end: var(--amplify-space-xs);
    --amplify-components-pagination-ellipsis-padding-inline-start: var(--amplify-space-xs);
    --amplify-components-pagination-ellipsis-justify-content: center;
    --amplify-components-pagination-ellipsis-align-items: baseline;
    --amplify-components-pagination-button-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-pagination-button-hover-color: var(--amplify-colors-font-primary);
    --amplify-components-pagination-button-hover-background-color: var(--amplify-colors-overlay-10);
    --amplify-components-pagination-button-transition-duration: var(--amplify-time-medium);
    --amplify-components-pagination-button-transition-property: background-color;
    --amplify-components-pagination-button-padding-inline-end: var(--amplify-space-xxs);
    --amplify-components-pagination-button-padding-inline-start: var(--amplify-space-xxs);
    --amplify-components-pagination-button-color: var(--amplify-colors-font-primary);
    --amplify-components-pagination-current-background-color: var(--amplify-colors-overlay-40);
    --amplify-components-pagination-current-font-size: var(--amplify-font-sizes-small);
    --amplify-components-pagination-current-color: var(--amplify-colors-white);
    --amplify-components-pagination-current-justify-content: center;
    --amplify-components-pagination-current-align-items: center;
    --amplify-components-menu-item-padding-inline-end: var(--amplify-space-medium);
    --amplify-components-menu-item-padding-inline-start: var(--amplify-space-medium);
    --amplify-components-menu-item-min-height: 2.5rem;
    --amplify-components-menu-large-height: var(--amplify-font-sizes-xxxl);
    --amplify-components-menu-large-width: var(--amplify-font-sizes-xxxl);
    --amplify-components-menu-small-height: var(--amplify-font-sizes-medium);
    --amplify-components-menu-small-width: var(--amplify-font-sizes-medium);
    --amplify-components-menu-min-width: 14rem;
    --amplify-components-menu-max-width: 30rem;
    --amplify-components-menu-gap: 0;
    --amplify-components-menu-flex-direction: column;
    --amplify-components-menu-box-shadow: var(--amplify-shadows-large);
    --amplify-components-menu-border-radius: var(--amplify-radii-medium);
    --amplify-components-menu-background-color: var(--amplify-colors-white);
    --amplify-components-loader-linear-large-stroke-width: var(--amplify-font-sizes-xs);
    --amplify-components-loader-linear-large-height: var(--amplify-font-sizes-xs);
    --amplify-components-loader-linear-small-stroke-width: var(--amplify-font-sizes-xxxs);
    --amplify-components-loader-linear-small-height: var(--amplify-font-sizes-xxxs);
    --amplify-components-loader-linear-animation-duration: 1s;
    --amplify-components-loader-linear-stroke-linecap: round;
    --amplify-components-loader-linear-stroke-empty: var(--amplify-colors-neutral-20);
    --amplify-components-loader-linear-stroke-filled: var(--amplify-colors-brand-primary-80);
    --amplify-components-loader-linear-stroke-width: var(--amplify-font-sizes-xxs);
    --amplify-components-loader-linear-height: var(--amplify-font-sizes-xxs);
    --amplify-components-loader-linear-min-width: 5rem;
    --amplify-components-loader-linear-width: 100%;
    --amplify-components-loader-large-height: var(--amplify-font-sizes-xxxl);
    --amplify-components-loader-large-width: var(--amplify-font-sizes-xxxl);
    --amplify-components-loader-small-height: var(--amplify-font-sizes-medium);
    --amplify-components-loader-small-width: var(--amplify-font-sizes-medium);
    --amplify-components-loader-animation-duration: 1s;
    --amplify-components-loader-stroke-linecap: round;
    --amplify-components-loader-stroke-filled: var(--amplify-colors-brand-primary-80);
    --amplify-components-loader-stroke-empty: var(--amplify-colors-neutral-20);
    --amplify-components-loader-height: var(--amplify-font-sizes-xxl);
    --amplify-components-loader-width: var(--amplify-font-sizes-xxl);
    --amplify-components-link-visited-color: var(--amplify-colors-font-interactive);
    --amplify-components-link-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-link-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-link-hover-color: var(--amplify-colors-font-hover);
    --amplify-components-link-focus-color: var(--amplify-colors-font-focus);
    --amplify-components-link-color: var(--amplify-colors-font-interactive);
    --amplify-components-link-active-color: var(--amplify-colors-font-active);
    --amplify-components-image-object-position: initial;
    --amplify-components-image-object-fit: initial;
    --amplify-components-image-height: auto;
    --amplify-components-image-max-width: 100%;
    --amplify-components-icon-height: 1em;
    --amplify-components-icon-line-height: 1;
    --amplify-components-heading-line-height: 1.25;
    --amplify-components-heading-color: var(--amplify-colors-font-primary);
    --amplify-components-heading-6-font-weight: var(--amplify-font-weights-bold);
    --amplify-components-heading-6-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-heading-5-font-weight: var(--amplify-font-weights-semibold);
    --amplify-components-heading-5-font-size: var(--amplify-font-sizes-large);
    --amplify-components-heading-4-font-weight: var(--amplify-font-weights-medium);
    --amplify-components-heading-4-font-size: var(--amplify-font-sizes-xl);
    --amplify-components-heading-3-font-weight: var(--amplify-font-weights-normal);
    --amplify-components-heading-3-font-size: var(--amplify-font-sizes-xxl);
    --amplify-components-heading-2-font-weight: var(--amplify-font-weights-light);
    --amplify-components-heading-2-font-size: var(--amplify-font-sizes-xxxl);
    --amplify-components-heading-1-font-weight: var(--amplify-font-weights-light);
    --amplify-components-heading-1-font-size: var(--amplify-font-sizes-xxxxl);
    --amplify-components-flex-flex-wrap: nowrap;
    --amplify-components-flex-align-content: normal;
    --amplify-components-flex-align-items: stretch;
    --amplify-components-flex-justify-content: normal;
    --amplify-components-flex-gap: var(--amplify-space-medium);
    --amplify-components-fieldmessages-description-font-size: var(--amplify-font-sizes-small);
    --amplify-components-fieldmessages-description-font-style: italic;
    --amplify-components-fieldmessages-description-color: var(--amplify-colors-font-secondary);
    --amplify-components-fieldmessages-error-font-size: var(--amplify-font-sizes-small);
    --amplify-components-fieldmessages-error-color: var(--amplify-colors-font-error);
    --amplify-components-fieldgroup-outer-align-items: center;
    --amplify-components-fieldgroup-vertical-align-items: center;
    --amplify-components-fieldgroup-gap: 0;
    --amplify-components-fieldcontrol-error-focus-box-shadow: 0px 0px 0px 1px var(--amplify-colors-border-error);
    --amplify-components-fieldcontrol-error-border-color: var(--amplify-colors-border-error);
    --amplify-components-fieldcontrol-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-fieldcontrol-disabled-border-color: var(--amplify-colors-transparent);
    --amplify-components-fieldcontrol-disabled-cursor: not-allowed;
    --amplify-components-fieldcontrol-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-fieldcontrol-focus-box-shadow: 0px 0px 0px 1px var(--amplify-colors-border-focus);
    --amplify-components-fieldcontrol-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-fieldcontrol-quiet-error-focus-box-shadow: 0px 1px var(--amplify-colors-border-error);
    --amplify-components-fieldcontrol-quiet-error-border-block-end-color: var(--amplify-colors-border-error);
    --amplify-components-fieldcontrol-quiet-focus-box-shadow: 0px 1px var(--amplify-colors-border-focus);
    --amplify-components-fieldcontrol-quiet-focus-border-block-end-color: var(--amplify-colors-border-focus);
    --amplify-components-fieldcontrol-quiet-border-radius: 0;
    --amplify-components-fieldcontrol-quiet-border-block-start: none;
    --amplify-components-fieldcontrol-quiet-border-inline-end: none;
    --amplify-components-fieldcontrol-quiet-border-inline-start: none;
    --amplify-components-fieldcontrol-quiet-border-block-end: var(--amplify-border-widths-small-solid-colors-border-primary-value);
    --amplify-components-fieldcontrol-quiet-border-style: none;
    --amplify-components-fieldcontrol-large-padding-inline-end: var(--amplify-space-medium);
    --amplify-components-fieldcontrol-large-padding-inline-start: var(--amplify-space-medium);
    --amplify-components-fieldcontrol-large-padding-block-end: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-large-padding-block-start: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-large-font-size: var(--amplify-components-field-large-font-size);
    --amplify-components-fieldcontrol-small-padding-inline-end: var(--amplify-space-small);
    --amplify-components-fieldcontrol-small-padding-inline-start: var(--amplify-space-small);
    --amplify-components-fieldcontrol-small-padding-block-end: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-small-padding-block-start: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-small-font-size: var(--amplify-components-field-small-font-size);
    --amplify-components-fieldcontrol-outline-offset: var(--amplify-outline-offsets-medium);
    --amplify-components-fieldcontrol-outline-width: var(--amplify-outline-widths-medium);
    --amplify-components-fieldcontrol-outline-style: solid;
    --amplify-components-fieldcontrol-outline-color: var(--amplify-colors-transparent);
    --amplify-components-fieldcontrol-transition-duration: var(--amplify-time-medium);
    --amplify-components-fieldcontrol-line-height: var(--amplify-line-heights-medium);
    --amplify-components-fieldcontrol-font-size: var(--amplify-components-field-font-size);
    --amplify-components-fieldcontrol-padding-inline-end: var(--amplify-space-medium);
    --amplify-components-fieldcontrol-padding-inline-start: var(--amplify-space-medium);
    --amplify-components-fieldcontrol-padding-block-end: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-padding-block-start: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-color: var(--amplify-colors-font-primary);
    --amplify-components-fieldcontrol-border-radius: var(--amplify-radii-small);
    --amplify-components-fieldcontrol-border-width: var(--amplify-border-widths-small);
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-border-primary);
    --amplify-components-fieldcontrol-border-style: solid;
    --amplify-components-field-label-color: var(--amplify-colors-font-secondary);
    --amplify-components-field-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-field-large-gap: var(--amplify-space-small);
    --amplify-components-field-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-field-small-gap: var(--amplify-space-xxxs);
    --amplify-components-field-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-field-gap: var(--amplify-space-xs);
    --amplify-components-expander-icon-transition-timing-function: cubic-bezier(.87, 0, .13, 1);
    --amplify-components-expander-icon-transition-duration: var(--amplify-time-medium);
    --amplify-components-expander-content-closed-animation-timing-function: cubic-bezier(.87, 0, .13, 1);
    --amplify-components-expander-content-closed-animation-duration: var(--amplify-time-medium);
    --amplify-components-expander-content-open-animation-timing-function: cubic-bezier(.87, 0, .13, 1);
    --amplify-components-expander-content-open-animation-duration: var(--amplify-time-medium);
    --amplify-components-expander-content-text-padding-block-end: var(--amplify-space-medium);
    --amplify-components-expander-content-text-padding-block-start: var(--amplify-space-medium);
    --amplify-components-expander-content-text-color: var(--amplify-colors-font-secondary);
    --amplify-components-expander-content-padding-inline-end: var(--amplify-space-large);
    --amplify-components-expander-content-padding-inline-start: var(--amplify-space-large);
    --amplify-components-expander-trigger-hover-background-color: var(--amplify-colors-overlay-10);
    --amplify-components-expander-trigger-justify-content: space-between;
    --amplify-components-expander-trigger-align-items: center;
    --amplify-components-expander-trigger-padding-inline-end: var(--amplify-space-large);
    --amplify-components-expander-trigger-padding-inline-start: var(--amplify-space-large);
    --amplify-components-expander-trigger-min-height: 3rem;
    --amplify-components-expander-header-box-shadow: 0 1px 0 var(--amplify-colors-overlay-20);
    --amplify-components-expander-item-focus-box-shadow: 0 0 0 2px var(--amplify-colors-border-focus);
    --amplify-components-expander-item-border-end-end-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-end-start-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-start-end-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-start-start-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-top-right-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-top-left-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-bottom-right-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-bottom-left-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-box-shadow: var(--amplify-shadows-small);
    --amplify-components-expander-item-margin-top: 1px;
    --amplify-components-expander-width: 100%;
    --amplify-components-expander-box-shadow: var(--amplify-shadows-large);
    --amplify-components-expander-border-radius: var(--amplify-radii-medium);
    --amplify-components-expander-background-color: var(--amplify-colors-white);
    --amplify-components-expander-display: block;
    --amplify-components-divider-opacity: var(--amplify-opacities-60);
    --amplify-components-divider-large-border-width: var(--amplify-border-widths-large);
    --amplify-components-divider-small-border-width: var(--amplify-border-widths-small);
    --amplify-components-divider-border-width: var(--amplify-border-widths-medium);
    --amplify-components-divider-border-color: var(--amplify-colors-border-primary);
    --amplify-components-divider-border-style: solid;
    --amplify-components-countrycodeselect-height: 100%;
    --amplify-components-checkboxfield-justify-content: center;
    --amplify-components-checkboxfield-flex-direction: column;
    --amplify-components-checkboxfield-align-content: center;
    --amplify-components-checkboxfield-align-items: flex-start;
    --amplify-components-checkbox-label-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-checkbox-icon-checked-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-checkbox-icon-checked-transform: scale(1);
    --amplify-components-checkbox-icon-checked-opacity: var(--amplify-opacities-100);
    --amplify-components-checkbox-icon-transition-timing-function: ease-in-out;
    --amplify-components-checkbox-icon-transition-duration: var(--amplify-time-short);
    --amplify-components-checkbox-icon-transition-property: all;
    --amplify-components-checkbox-icon-transform: scale(0);
    --amplify-components-checkbox-icon-opacity: var(--amplify-opacities-0);
    --amplify-components-checkbox-icon-border-radius: 20%;
    --amplify-components-checkbox-icon-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-checkbox-button-error-focus-box-shadow: 0px 0px 0px 2px var(--amplify-colors-border-error);
    --amplify-components-checkbox-button-error-focus-border-color: var(--amplify-colors-transparent);
    --amplify-components-checkbox-button-error-border-color: var(--amplify-colors-border-error);
    --amplify-components-checkbox-button-disabled-border-color: var(--amplify-colors-border-disabled);
    --amplify-components-checkbox-button-focus-box-shadow: 0px 0px 0px 2px var(--amplify-colors-border-focus);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-transparent);
    --amplify-components-checkbox-button-focus-outline-offset: var(--amplify-outline-offsets-medium);
    --amplify-components-checkbox-button-focus-outline-width: var(--amplify-outline-widths-medium);
    --amplify-components-checkbox-button-focus-outline-style: solid;
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-transparent);
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-border-primary);
    --amplify-components-checkbox-button-before-border-style: solid;
    --amplify-components-checkbox-button-before-border-radius: 20%;
    --amplify-components-checkbox-button-before-border-width: var(--amplify-border-widths-medium);
    --amplify-components-checkbox-button-before-height: 100%;
    --amplify-components-checkbox-button-before-width: 100%;
    --amplify-components-checkbox-button-color: var(--amplify-colors-white);
    --amplify-components-checkbox-button-justify-content: center;
    --amplify-components-checkbox-button-align-items: center;
    --amplify-components-checkbox-button-position: relative;
    --amplify-components-checkbox-disabled-cursor: not-allowed;
    --amplify-components-checkbox-align-items: center;
    --amplify-components-checkbox-cursor: pointer;
    --amplify-components-card-elevated-box-shadow: var(--amplify-shadows-medium);
    --amplify-components-card-elevated-border-color: transparent;
    --amplify-components-card-elevated-border-style: solid;
    --amplify-components-card-elevated-border-width: 0;
    --amplify-components-card-elevated-border-radius: var(--amplify-radii-xs);
    --amplify-components-card-elevated-background-color: var(--amplify-components-card-background-color);
    --amplify-components-card-outlined-border-color: var(--amplify-colors-border-primary);
    --amplify-components-card-outlined-border-style: solid;
    --amplify-components-card-outlined-border-width: var(--amplify-border-widths-small);
    --amplify-components-card-outlined-border-radius: var(--amplify-radii-xs);
    --amplify-components-card-outlined-background-color: var(--amplify-components-card-background-color);
    --amplify-components-card-padding: var(--amplify-space-medium);
    --amplify-components-card-box-shadow: none;
    --amplify-components-card-border-color: transparent;
    --amplify-components-card-border-style: solid;
    --amplify-components-card-border-width: 0;
    --amplify-components-card-border-radius: var(--amplify-radii-xs);
    --amplify-components-card-background-color: var(--amplify-colors-background-primary);
    --amplify-components-button-large-padding-inline-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
    --amplify-components-button-large-padding-inline-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
    --amplify-components-button-large-padding-block-end: var(--amplify-components-fieldcontrol-large-padding-block-end);
    --amplify-components-button-large-padding-block-start: var(--amplify-components-fieldcontrol-large-padding-block-start);
    --amplify-components-button-large-font-size: var(--amplify-components-fieldcontrol-large-font-size);
    --amplify-components-button-small-padding-inline-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
    --amplify-components-button-small-padding-inline-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
    --amplify-components-button-small-padding-block-end: var(--amplify-components-fieldcontrol-small-padding-block-end);
    --amplify-components-button-small-padding-block-start: var(--amplify-components-fieldcontrol-small-padding-block-start);
    --amplify-components-button-small-font-size: var(--amplify-components-fieldcontrol-small-font-size);
    --amplify-components-button-link-loading-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-link-loading-background-color: transparent;
    --amplify-components-button-link-loading-border-color: transparent;
    --amplify-components-button-link-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-link-disabled-background-color: transparent;
    --amplify-components-button-link-disabled-border-color: transparent;
    --amplify-components-button-link-active-color: var(--amplify-colors-font-active);
    --amplify-components-button-link-active-background-color: var(--amplify-colors-brand-primary-20);
    --amplify-components-button-link-active-border-color: transparent;
    --amplify-components-button-link-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-button-link-focus-color: var(--amplify-colors-font-focus);
    --amplify-components-button-link-focus-background-color: var(--amplify-colors-brand-primary-10);
    --amplify-components-button-link-focus-border-color: transparent;
    --amplify-components-button-link-hover-color: var(--amplify-colors-font-hover);
    --amplify-components-button-link-hover-background-color: var(--amplify-colors-brand-primary-10);
    --amplify-components-button-link-hover-border-color: transparent;
    --amplify-components-button-link-color: var(--amplify-colors-font-interactive);
    --amplify-components-button-link-background-color: transparent;
    --amplify-components-button-link-border-color: transparent;
    --amplify-components-button-link-border-width: 0;
    --amplify-components-button-menu-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-menu-active-background-color: var(--amplify-colors-brand-primary-90);
    --amplify-components-button-menu-active-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-menu-focus-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-button-menu-focus-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-menu-hover-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-button-menu-hover-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-menu-justify-content: start;
    --amplify-components-button-menu-background-color: transparent;
    --amplify-components-button-menu-border-width: 0;
    --amplify-components-button-primary-active-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-active-background-color: var(--amplify-colors-brand-primary-100);
    --amplify-components-button-primary-active-border-color: transparent;
    --amplify-components-button-primary-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-button-primary-focus-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-focus-background-color: var(--amplify-colors-brand-primary-90);
    --amplify-components-button-primary-focus-border-color: transparent;
    --amplify-components-button-primary-hover-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-hover-background-color: var(--amplify-colors-brand-primary-90);
    --amplify-components-button-primary-hover-border-color: transparent;
    --amplify-components-button-primary-loading-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-loading-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-button-primary-loading-border-color: transparent;
    --amplify-components-button-primary-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-primary-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-button-primary-disabled-border-color: transparent;
    --amplify-components-button-primary-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-button-primary-border-style: solid;
    --amplify-components-button-primary-border-width: var(--amplify-border-widths-small);
    --amplify-components-button-primary-border-color: transparent;
    --amplify-components-button-disabled-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-button-disabled-background-color: transparent;
    --amplify-components-button-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-loading-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-button-loading-background-color: transparent;
    --amplify-components-button-loading-color: var(--amplify-colors-font-tertiary);
    --amplify-components-button-active-border-color: var(--amplify-colors-brand-primary-100);
    --amplify-components-button-active-background-color: var(--amplify-colors-brand-primary-20);
    --amplify-components-button-active-color: var(--amplify-colors-font-active);
    --amplify-components-button-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-button-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-button-focus-background-color: var(--amplify-colors-brand-primary-10);
    --amplify-components-button-focus-color: var(--amplify-colors-font-focus);
    --amplify-components-button-hover-border-color: var(--amplify-colors-brand-primary-60);
    --amplify-components-button-hover-background-color: var(--amplify-colors-brand-primary-10);
    --amplify-components-button-hover-color: var(--amplify-colors-font-focus);
    --amplify-components-button-color: var(--amplify-colors-font-primary);
    --amplify-components-button-border-radius: var(--amplify-components-fieldcontrol-border-radius);
    --amplify-components-button-border-style: var(--amplify-components-fieldcontrol-border-style);
    --amplify-components-button-border-width: var(--amplify-components-fieldcontrol-border-width);
    --amplify-components-button-border-color: var(--amplify-components-fieldcontrol-border-color);
    --amplify-components-button-padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end);
    --amplify-components-button-padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    --amplify-components-button-padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end);
    --amplify-components-button-padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start);
    --amplify-components-button-line-height: var(--amplify-components-fieldcontrol-line-height);
    --amplify-components-button-font-size: var(--amplify-components-fieldcontrol-font-size);
    --amplify-components-button-transition-duration: var(--amplify-components-fieldcontrol-transition-duration);
    --amplify-components-button-font-weight: var(--amplify-font-weights-bold);
    --amplify-components-badge-large-padding-horizontal: var(--amplify-space-medium);
    --amplify-components-badge-large-padding-vertical: var(--amplify-space-small);
    --amplify-components-badge-large-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-badge-small-padding-horizontal: var(--amplify-space-xs);
    --amplify-components-badge-small-padding-vertical: var(--amplify-space-xxs);
    --amplify-components-badge-small-font-size: var(--amplify-font-sizes-xs);
    --amplify-components-badge-error-background-color: var(--amplify-colors-background-error);
    --amplify-components-badge-error-color: var(--amplify-colors-font-error);
    --amplify-components-badge-success-background-color: var(--amplify-colors-background-success);
    --amplify-components-badge-success-color: var(--amplify-colors-font-success);
    --amplify-components-badge-warning-background-color: var(--amplify-colors-background-warning);
    --amplify-components-badge-warning-color: var(--amplify-colors-font-warning);
    --amplify-components-badge-info-background-color: var(--amplify-colors-background-info);
    --amplify-components-badge-info-color: var(--amplify-colors-font-info);
    --amplify-components-badge-border-radius: var(--amplify-radii-xl);
    --amplify-components-badge-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-badge-padding-horizontal: var(--amplify-space-small);
    --amplify-components-badge-padding-vertical: var(--amplify-space-xs);
    --amplify-components-badge-text-align: center;
    --amplify-components-badge-font-size: var(--amplify-font-sizes-small);
    --amplify-components-badge-font-weight: var(--amplify-font-weights-semibold);
    --amplify-components-badge-line-height: 1;
    --amplify-components-badge-color: var(--amplify-colors-font-primary);
    --amplify-components-alert-success-background-color: var(--amplify-colors-background-success);
    --amplify-components-alert-success-color: var(--amplify-colors-font-success);
    --amplify-components-alert-warning-background-color: var(--amplify-colors-background-warning);
    --amplify-components-alert-warning-color: var(--amplify-colors-font-warning);
    --amplify-components-alert-error-background-color: var(--amplify-colors-background-error);
    --amplify-components-alert-error-color: var(--amplify-colors-font-error);
    --amplify-components-alert-info-background-color: var(--amplify-colors-background-info);
    --amplify-components-alert-info-color: var(--amplify-colors-font-info);
    --amplify-components-alert-heading-font-weight: var(--amplify-font-weights-bold);
    --amplify-components-alert-heading-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-alert-icon-size: var(--amplify-font-sizes-xl);
    --amplify-components-alert-padding-inline: var(--amplify-space-medium);
    --amplify-components-alert-padding-block: var(--amplify-space-small);
    --amplify-components-alert-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-alert-color: var(--amplify-colors-font-primary);
    --amplify-components-alert-justify-content: space-between;
    --amplify-components-alert-align-items: center
}

[data-amplify-theme] {
    font-family: var(--amplify-fonts-default-static)
}

@supports (font-variation-settings: normal) {
    [data-amplify-theme] {
        font-family: var(--amplify-fonts-default-variable)
    }
}

[class*=amplify] {
    all: unset
}

[data-amplify-theme] * {
    box-sizing: border-box
}

.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
}

.amplify-text {
    color: var(--amplify-components-text-color);
    display: block
}

b.amplify-text,
em.amplify-text,
i.amplify-text,
span.amplify-text,
strong.amplify-text {
    display: inline
}

.amplify-text[data-truncate=true] {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.amplify-text[data-variation=primary] {
    color: var(--amplify-components-text-primary-color)
}

.amplify-text[data-variation=secondary] {
    color: var(--amplify-components-text-secondary-color)
}

.amplify-text[data-variation=tertiary] {
    color: var(--amplify-components-text-tertiary-color)
}

.amplify-text[data-variation=error] {
    color: var(--amplify-components-text-error-color)
}

.amplify-text[data-variation=info] {
    color: var(--amplify-components-text-info-color)
}

.amplify-text[data-variation=success] {
    color: var(--amplify-components-text-success-color)
}

.amplify-text[data-variation=warning] {
    color: var(--amplify-components-text-warning-color)
}

.amplify-badge {
    color: var(--amplify-components-badge-color);
    background-color: var(--amplify-components-badge-background-color);
    border-radius: var(--amplify-components-badge-border-radius);
    display: inline-flex;
    font-size: var(--amplify-components-badge-font-size);
    font-weight: var(--amplify-components-badge-font-weight);
    padding: var(--amplify-components-badge-padding-vertical) var(--amplify-components-badge-padding-horizontal);
    text-align: var(--amplify-components-badge-text-align);
    line-height: var(--amplify-components-badge-line-height)
}

.amplify-badge[data-variation=info] {
    color: var(--amplify-components-badge-info-color);
    background-color: var(--amplify-components-badge-info-background-color)
}

.amplify-badge[data-variation=error] {
    color: var(--amplify-components-badge-error-color);
    background-color: var(--amplify-components-badge-error-background-color)
}

.amplify-badge[data-variation=warning] {
    color: var(--amplify-components-badge-warning-color);
    background-color: var(--amplify-components-badge-warning-background-color)
}

.amplify-badge[data-variation=success] {
    color: var(--amplify-components-badge-success-color);
    background-color: var(--amplify-components-badge-success-background-color)
}

.amplify-badge[data-size=small] {
    font-size: var(--amplify-components-badge-small-font-size);
    padding: var(--amplify-components-badge-small-padding-vertical) var(--amplify-components-badge-small-padding-horizontal)
}

.amplify-badge[data-size=large] {
    font-size: var(--amplify-components-badge-large-font-size);
    padding: var(--amplify-components-badge-large-padding-vertical) var(--amplify-components-badge-large-padding-horizontal)
}

.amplify-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-weight: var(--amplify-components-button-font-weight);
    transition: all var(--amplify-components-button-transition-duration);
    border-width: var(--amplify-components-button-border-width);
    border-style: var(--amplify-components-button-border-style);
    border-color: var(--amplify-components-button-border-color);
    border-radius: var(--amplify-components-button-border-radius);
    color: var(--amplify-components-button-color);
    font-size: var(--amplify-components-button-font-size);
    line-height: var(--amplify-components-button-line-height);
    -webkit-padding-before: var(--amplify-components-button-padding-block-start);
    padding-block-start: var(--amplify-components-button-padding-block-start);
    -webkit-padding-after: var(--amplify-components-button-padding-block-end);
    padding-block-end: var(--amplify-components-button-padding-block-end);
    -webkit-padding-start: var(--amplify-components-button-padding-inline-start);
    padding-inline-start: var(--amplify-components-button-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-button-padding-inline-end);
    padding-inline-end: var(--amplify-components-button-padding-inline-end);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer
}

.amplify-button:hover {
    background-color: var(--amplify-components-button-hover-background-color);
    border-color: var(--amplify-components-button-hover-border-color);
    color: var(--amplify-components-button-hover-color)
}

.amplify-button:focus {
    background-color: var(--amplify-components-button-hover-background-color);
    border-color: var(--amplify-components-button-focus-border-color);
    color: var(--amplify-components-button-focus-color);
    box-shadow: var(--amplify-components-button-focus-box-shadow)
}

.amplify-button:active {
    background-color: var(--amplify-components-button-active-background-color);
    border-color: var(--amplify-components-button-active-border-color);
    color: var(--amplify-components-button-active-color)
}

.amplify-button[disabled] {
    background-color: var(--amplify-components-button-disabled-background-color);
    border-color: var(--amplify-components-button-disabled-border-color);
    color: var(--amplify-components-button-disabled-color);
    cursor: not-allowed
}

.amplify-button[data-loading=true] {
    background-color: var(--amplify-components-button-loading-background-color);
    border-color: var(--amplify-components-button-loading-border-color);
    color: var(--amplify-components-button-loading-color)
}

.amplify-button[data-fullwidth=true] {
    width: 100%
}

.amplify-button[data-variation=menu] {
    border-width: var(--amplify-components-button-menu-border-width);
    background-color: var(--amplify-components-button-menu-background-color);
    justify-content: var(--amplify-components-button-menu-justify-content)
}

.amplify-button[data-variation=menu]:hover {
    color: var(--amplify-components-button-menu-hover-color);
    background-color: var(--amplify-components-button-menu-hover-background-color)
}

.amplify-button[data-variation=menu]:focus {
    box-shadow: none;
    color: var(--amplify-components-button-menu-focus-color);
    background-color: var(--amplify-components-button-menu-focus-background-color)
}

.amplify-button[data-variation=menu]:active {
    color: var(--amplify-components-button-menu-active-color);
    background-color: var(--amplify-components-button-menu-active-background-color)
}

.amplify-button[data-variation=menu][disabled] {
    color: var(--amplify-components-button-menu-disabled-color)
}

.amplify-button[data-variation=primary] {
    border-width: var(--amplify-components-button-primary-border-width);
    background-color: var(--amplify-components-button-primary-background-color);
    border-color: var(--amplify-components-button-primary-border-color);
    color: var(--amplify-components-button-primary-color)
}

.amplify-button[data-variation=primary]:hover {
    background-color: var(--amplify-components-button-primary-hover-background-color);
    border-color: var(--amplify-components-button-primary-hover-border-color);
    color: var(--amplify-components-button-primary-hover-color)
}

.amplify-button[data-variation=primary]:focus {
    background-color: var(--amplify-components-button-primary-focus-background-color);
    border-color: var(--amplify-components-button-primary-focus-border-color);
    color: var(--amplify-components-button-primary-focus-color);
    box-shadow: var(--amplify-components-button-primary-focus-box-shadow)
}

.amplify-button[data-variation=primary]:active {
    background-color: var(--amplify-components-button-primary-active-background-color);
    border-color: var(--amplify-components-button-primary-active-border-color);
    color: var(--amplify-components-button-primary-active-color)
}

.amplify-button[data-variation=primary][data-loading=true] {
    background-color: var(--amplify-components-button-primary-loading-background-color);
    border-color: var(--amplify-components-button-primary-loading-border-color);
    color: var(--amplify-components-button-primary-loading-color)
}

.amplify-button[data-variation=primary][disabled] {
    background-color: var(--amplify-components-button-primary-disabled-background-color);
    border-color: var(--amplify-components-button-primary-disabled-border-color);
    color: var(--amplify-components-button-primary-disabled-color)
}

.amplify-button[data-variation=link] {
    border-width: var(--amplify-components-button-link-border-width);
    background-color: var(--amplify-components-button-link-background-color);
    color: var(--amplify-components-button-link-color)
}

.amplify-button[data-variation=link]:hover {
    background-color: var(--amplify-components-button-link-hover-background-color);
    border-color: var(--amplify-components-button-link-hover-border-color);
    color: var(--amplify-components-button-link-hover-color)
}

.amplify-button[data-variation=link]:focus {
    background-color: var(--amplify-components-button-link-focus-background-color);
    border-color: var(--amplify-components-button-link-focus-border-color);
    color: var(--amplify-components-button-link-focus-color);
    box-shadow: var(--amplify-components-button-link-focus-box-shadow)
}

.amplify-button[data-variation=link]:active {
    background-color: var(--amplify-components-button-link-active-background-color);
    border-color: var(--amplify-components-button-link-active-border-color);
    color: var(--amplify-components-button-link-active-color)
}

.amplify-button[data-variation=link][disabled] {
    background-color: var(--amplify-components-button-link-disabled-background-color);
    border-color: var(--amplify-components-button-link-disabled-border-color);
    color: var(--amplify-components-button-link-disabled-color);
    text-decoration: none
}

.amplify-button[data-variation=link][data-loading=true] {
    background-color: var(--amplify-components-button-link-loading-background-color);
    border-color: var(--amplify-components-button-link-loading-border-color);
    color: var(--amplify-components-button-link-loading-color);
    text-decoration: none
}

.amplify-button[data-size=small] {
    font-size: var(--amplify-components-button-small-font-size);
    -webkit-padding-before: var(--amplify-components-button-small-padding-block-start);
    padding-block-start: var(--amplify-components-button-small-padding-block-start);
    -webkit-padding-after: var(--amplify-components-button-small-padding-block-end);
    padding-block-end: var(--amplify-components-button-small-padding-block-end);
    -webkit-padding-start: var(--amplify-components-button-small-padding-inline-start);
    padding-inline-start: var(--amplify-components-button-small-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-button-small-padding-inline-end);
    padding-inline-end: var(--amplify-components-button-small-padding-inline-end)
}

.amplify-button[data-size=large] {
    font-size: var(--amplify-components-button-large-font-size);
    -webkit-padding-before: var(--amplify-components-button-large-padding-block-start);
    padding-block-start: var(--amplify-components-button-large-padding-block-start);
    -webkit-padding-after: var(--amplify-components-button-large-padding-block-end);
    padding-block-end: var(--amplify-components-button-large-padding-block-end);
    -webkit-padding-start: var(--amplify-components-button-large-padding-inline-start);
    padding-inline-start: var(--amplify-components-button-large-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-button-large-padding-inline-end);
    padding-inline-end: var(--amplify-components-button-large-padding-inline-end)
}

@media (prefers-reduced-motion: reduce) {
    .amplify-button {
        transition: none
    }
}

.amplify-field__description {
    color: var(--amplify-components-fieldmessages-description-color);
    font-style: var(--amplify-components-fieldmessages-description-font-style);
    font-size: var(--amplify-components-fieldmessages-description-font-size)
}

.amplify-field__error-message {
    color: var(--amplify-components-fieldmessages-error-color);
    font-size: var(--amplify-components-fieldmessages-error-font-size)
}

.amplify-heading {
    color: var(--amplify-components-heading-color);
    line-height: var(--amplify-components-heading-line-height);
    display: block
}

h1.amplify-heading {
    font-size: var(--amplify-components-heading-1-font-size);
    font-weight: var(--amplify-components-heading-1-font-weight)
}

h2.amplify-heading {
    font-size: var(--amplify-components-heading-2-font-size);
    font-weight: var(--amplify-components-heading-2-font-weight)
}

h3.amplify-heading {
    font-size: var(--amplify-components-heading-3-font-size);
    font-weight: var(--amplify-components-heading-3-font-weight)
}

h4.amplify-heading {
    font-size: var(--amplify-components-heading-4-font-size);
    font-weight: var(--amplify-components-heading-4-font-weight)
}

h5.amplify-heading {
    font-size: var(--amplify-components-heading-5-font-size);
    font-weight: var(--amplify-components-heading-5-font-weight)
}

h6.amplify-heading {
    font-size: var(--amplify-components-heading-6-font-size);
    font-weight: var(--amplify-components-heading-6-font-weight)
}

.amplify-icon {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    height: var(--amplify-components-icon-height);
    line-height: var(--amplify-components-icon-line-height)
}

.amplify-icon>svg {
    height: var(--amplify-components-icon-height);
    width: var(--amplify-components-icon-height)
}

.amplify-image {
    height: var(--amplify-components-image-height);
    max-width: var(--amplify-components-image-max-width);
    -o-object-fit: var(--amplify-components-image-object-fit);
    object-fit: var(--amplify-components-image-object-fit);
    -o-object-position: var(--amplify-components-image-object-position);
    object-position: var(--amplify-components-image-object-position)
}

.amplify-link {
    color: var(--amplify-components-link-color);
    -webkit-text-decoration: var(--amplify-components-link-text-decoration);
    text-decoration: var(--amplify-components-link-text-decoration);
    cursor: pointer
}

.amplify-link:active {
    color: var(--amplify-components-link-active-color);
    -webkit-text-decoration: var(--amplify-components-link-active-text-decoration);
    text-decoration: var(--amplify-components-link-active-text-decoration)
}

.amplify-link:focus {
    color: var(--amplify-components-link-focus-color);
    -webkit-text-decoration: var(--amplify-components-link-focus-text-decoration);
    text-decoration: var(--amplify-components-link-focus-text-decoration)
}

.amplify-link:hover {
    color: var(--amplify-components-link-hover-color);
    -webkit-text-decoration: var(--amplify-components-link-hover-text-decoration);
    text-decoration: var(--amplify-components-link-hover-text-decoration)
}

.amplify-link:visited {
    color: var(--amplify-components-link-visited-color);
    -webkit-text-decoration: var(--amplify-components-link-visited-text-decoration);
    text-decoration: var(--amplify-components-link-visited-text-decoration)
}

.amplify-link[data-size=small] {
    font-size: var(--amplify-components-link-small-font-size)
}

.amplify-link[data-size=large] {
    font-size: var(--amplify-components-link-large-font-size)
}

.amplify-loader {
    width: var(--amplify-components-loader-width);
    height: var(--amplify-components-loader-height);
    fill: none;
    stroke: var(--amplify-components-loader-stroke-empty)
}

.amplify-loader circle:last-of-type {
    transform-origin: center center;
    -webkit-animation-name: amplify-loader-circular;
    animation-name: amplify-loader-circular;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: var(--amplify-components-loader-animation-duration);
    animation-duration: var(--amplify-components-loader-animation-duration);
    stroke: var(--amplify-components-loader-stroke-filled);
    stroke-linecap: var(--amplify-components-loader-stroke-linecap)
}

@media (prefers-reduced-motion) {
    .amplify-loader circle:last-of-type {
        -webkit-animation: none;
        animation: none;
        stroke-dasharray: 100% 200%;
        stroke-dashoffset: 50%
    }
}

.amplify-loader[data-size=small] {
    width: var(--amplify-components-loader-small-width);
    height: var(--amplify-components-loader-small-height)
}

.amplify-loader[data-size=large] {
    width: var(--amplify-components-loader-large-width);
    height: var(--amplify-components-loader-large-height)
}

.amplify-loader line:last-of-type {
    stroke: var(--amplify-components-loader-linear-stroke-filled);
    stroke-dasharray: 50% 200%;
    -webkit-animation-name: amplify-loader-linear;
    animation-name: amplify-loader-linear;
    -webkit-animation-duration: var(--amplify-components-loader-linear-animation-duration);
    animation-duration: var(--amplify-components-loader-linear-animation-duration);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

@media (prefers-reduced-motion) {
    .amplify-loader line:last-of-type {
        -webkit-animation: none;
        animation: none;
        stroke-dashoffset: -50%
    }
}

.amplify-loader[data-variation=linear] {
    width: var(--amplify-components-loader-linear-width);
    min-width: var(--amplify-components-loader-linear-min-width);
    height: var(--amplify-components-loader-linear-height);
    stroke: var(--amplify-components-loader-linear-stroke-empty);
    stroke-width: var(--amplify-components-loader-linear-stroke-width);
    stroke-linecap: var(--amplify-components-loader-linear-stroke-linecap)
}

.amplify-loader[data-variation=linear][data-size=small] {
    height: var(--amplify-components-loader-linear-small-height);
    stroke-width: var(--amplify-components-loader-linear-small-stroke-width)
}

.amplify-loader[data-variation=linear][data-size=large] {
    height: var(--amplify-components-loader-linear-large-height);
    stroke-width: var(--amplify-components-loader-linear-large-stroke-width)
}

@-webkit-keyframes amplify-loader-circular {
    0% {
        stroke-dasharray: 100% 200%;
        transform: rotate(120deg)
    }

    50% {
        stroke-dasharray: 20% 400%
    }

    to {
        stroke-dasharray: 100% 200%;
        transform: rotate(480deg)
    }
}

@keyframes amplify-loader-circular {
    0% {
        stroke-dasharray: 100% 200%;
        transform: rotate(120deg)
    }

    50% {
        stroke-dasharray: 20% 400%
    }

    to {
        stroke-dasharray: 100% 200%;
        transform: rotate(480deg)
    }
}

@-webkit-keyframes amplify-loader-linear {
    0% {
        stroke-dashoffset: 200%
    }

    to {
        stroke-dashoffset: -50%
    }
}

@keyframes amplify-loader-linear {
    0% {
        stroke-dashoffset: 200%
    }

    to {
        stroke-dashoffset: -50%
    }
}

.amplify-placeholder {
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-duration: var(--amplify-components-placeholder-transition-duration);
    animation-duration: var(--amplify-components-placeholder-transition-duration);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: amplify-placeholder-loading;
    animation-name: amplify-placeholder-loading;
    border-radius: var(--amplify-components-placeholder-border-radius);
    height: var(--amplify-components-placeholder-default-height);
    width: 100%;
    display: block
}

.amplify-placeholder[data-size=small] {
    height: var(--amplify-components-placeholder-small-height)
}

.amplify-placeholder[data-size=large] {
    height: var(--amplify-components-placeholder-large-height)
}

@-webkit-keyframes amplify-placeholder-loading {
    0% {
        background-color: var(--amplify-components-placeholder-start-color)
    }

    to {
        background-color: var(--amplify-components-placeholder-end-color)
    }
}

@keyframes amplify-placeholder-loading {
    0% {
        background-color: var(--amplify-components-placeholder-start-color)
    }

    to {
        background-color: var(--amplify-components-placeholder-end-color)
    }
}

@media (prefers-reduced-motion: reduce) {
    .amplify-placeholder {
        -webkit-animation: none;
        animation: none;
        background-color: var(--amplify-components-placeholder-end-color)
    }
}

.amplify-flex {
    align-content: var(--amplify-components-flex-align-content);
    align-items: var(--amplify-components-flex-align-items);
    display: flex;
    flex-wrap: var(--amplify-components-flex-flex-wrap);
    gap: var(--amplify-components-flex-gap);
    justify-content: var(--amplify-components-flex-justify-content)
}

.amplify-grid {
    display: grid
}

.amplify-scrollview {
    display: block;
    overflow: scroll
}

.amplify-scrollview[data-orientation=horizontal] {
    overflow-x: scroll;
    overflow-y: initial
}

.amplify-scrollview[data-orientation=vertical] {
    overflow-x: initial;
    overflow-y: scroll
}

.amplify-alert {
    align-items: var(--amplify-components-alert-align-items);
    justify-content: var(--amplify-components-alert-justify-content);
    background-color: var(--amplify-components-alert-background-color);
    padding-block: var(--amplify-components-alert-padding-block);
    padding-inline: var(--amplify-components-alert-padding-inline)
}

.amplify-alert[data-variation=info] {
    color: var(--amplify-components-alert-info-color);
    background-color: var(--amplify-components-alert-info-background-color)
}

.amplify-alert[data-variation=error] {
    color: var(--amplify-components-alert-error-color);
    background-color: var(--amplify-components-alert-error-background-color)
}

.amplify-alert[data-variation=warning] {
    color: var(--amplify-components-alert-warning-color);
    background-color: var(--amplify-components-alert-warning-background-color)
}

.amplify-alert[data-variation=success] {
    color: var(--amplify-components-alert-success-color);
    background-color: var(--amplify-components-alert-success-background-color)
}

.amplify-alert__icon {
    font-size: var(--amplify-components-alert-icon-size)
}

.amplify-alert__heading {
    display: block;
    font-weight: var(--amplify-components-alert-heading-font-weight);
    font-size: var(--amplify-components-alert-heading-font-size)
}

.amplify-alert__body {
    color: inherit;
    display: block
}

.amplify-button.amplify-alert__dismiss {
    color: inherit
}

[data-amplify-authenticator] {
    display: grid;
}

[data-amplify-authenticator][data-variation=modal] {
    display: grid;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    background-color: var(--amplify-colors-background-secondary, #e1e5e9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    padding-top: 20vh;
    align-content: flex-start;
    background-image: url(./assets/login7.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    /* background-image: linear-gradient(70deg,
            #faba43 5%,
            #f7cc7c 5% 10%,
            #ffe2ad 10% 15%,
            #ffeed1 15% 20%,
            #fff4db 20% 30%,
            #fff7e7 30% 40%,
            #fffbf5 40% 80%,
            #ffffff 80% 100%); */
}

@media (max-width: 30rem) {
    [data-amplify-authenticator][data-variation=modal] {
        overflow-y: auto;
        padding-top: 0
    }
}

[data-amplify-authenticator][data-variation=modal] [data-amplify-router-content] {
    max-height: 60vh;
    overflow-y: auto;
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top, linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)) center top, radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)) center bottom;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll
}

@media (max-width: 30rem) {
    [data-amplify-authenticator][data-variation=modal] [data-amplify-router-content] {
        max-height: unset
    }
}

[data-amplify-authenticator][data-variation=modal]:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='texture' data-v-1d260e0e=''%3E%3Cfilter id='noise' data-v-1d260e0e=''%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.8' numOctaves='4' stitchTiles='stitch' data-v-1d260e0e=''%3E%3C/feTurbulence%3E%3CfeColorMatrix type='saturate' values='0' data-v-1d260e0e=''%3E%3C/feColorMatrix%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)' data-v-1d260e0e=''%3E%3C/rect%3E%3C/svg%3E");
    opacity: .0;
    filter: "contrast(120%) brightness(120%)"
}

[data-amplify-authenticator] [data-amplify-container] {
    position: relative;
    place-self: center;
    width: 30rem
}

@media (max-width: 30rem) {
    [data-amplify-authenticator] [data-amplify-container] {
        width: 100%
    }
}

[data-amplify-authenticator] [data-amplify-router] [data-amplify-footer] {
    padding-bottom: var(--amplify-space-medium);
    text-align: center
}

[data-amplify-authenticator] [data-amplify-router] {
    background-color: var(--amplify-colors-background-primary);
    box-shadow: var(--amplify-shadows-medium)
}

[data-amplify-authenticator] [data-amplify-form] {
    padding: var(--amplify-space-xl)
}

[data-amplify-authenticator] [data-state=inactive][role=tab] {
    background-color: var(--amplify-colors-background-secondary)
}

[data-amplify-authenticator] .amplify-passwordfield .amplify-button:not(:focus) {
    border-left: none
}

[data-amplify-authenticator] .amplify-phonenumberfield .amplify-countrycodeselect,
[data-amplify-authenticator] .amplify-phonenumberfield .amplify-select__wrapper {
    height: 100%
}

[data-amplify-authenticator] .amplify-phonenumberfield select:not(:focus) {
    border-right: none
}

[data-amplify-authenticator] [data-or-container] {
    position: relative;
    color: gray;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0px
}

[data-amplify-authenticator] [data-or-container] [data-or-line] {
    position: absolute;
    background: var(--amplify-colors-white);
    z-index: 1;
    padding: 0px 1.5rem
}

[data-amplify-copy] {
    display: flex;
    font-size: .688rem;
    gap: 1rem;
    justify-content: center;
    align-items: center
}

@media (max-width: 30rem) {
    [data-amplify-copy] {
        word-break: break-all
    }
}

[data-amplify-copy-svg] {
    cursor: pointer;
    position: relative
}

[data-amplify-copy-svg]:hover [data-amplify-copy-tooltip] {
    visibility: visible;
    font-size: .55rem
}

[data-amplify-copy-tooltip] {
    visibility: hidden;
    position: absolute;
    top: -1rem;
    left: -.1rem;
    color: var(--amplify-colors-teal-100)
}

.amplify-card {
    background-color: var(--amplify-components-card-background-color);
    border-radius: var(--amplify-components-card-border-radius);
    border-width: var(--amplify-components-card-border-width);
    border-style: var(--amplify-components-card-border-style);
    border-color: var(--amplify-components-card-border-color);
    box-shadow: var(--amplify-components-card-box-shadow);
    display: inline-block;
    padding: var(--amplify-components-card-padding)
}

.amplify-card[data-variation=outlined] {
    background-color: var(--amplify-components-card-outlined-background-color);
    border-radius: var(--amplify-components-card-outlined-border-radius);
    border-width: var(--amplify-components-card-outlined-border-width);
    border-style: var(--amplify-components-card-outlined-border-style);
    border-color: var(--amplify-components-card-outlined-border-color);
    box-shadow: var(--amplify-components-card-outlined-box-shadow)
}

.amplify-card[data-variation=elevated] {
    background-color: var(--amplify-components-card-elevated-background-color);
    border-radius: var(--amplify-components-card-elevated-border-radius);
    border-width: var(--amplify-components-card-elevated-border-width);
    border-style: var(--amplify-components-card-elevated-border-style);
    border-color: var(--amplify-components-card-elevated-border-color);
    box-shadow: var(--amplify-components-card-elevated-box-shadow)
}

.amplify-collection {
    flex-direction: column
}

.amplify-collection-search {
    flex-direction: row;
    justify-content: center
}

.amplify-collection-pagination {
    justify-content: center
}

.amplify-checkbox {
    cursor: var(--amplify-components-checkbox-cursor);
    align-items: var(--amplify-components-checkbox-align-items);
    gap: inherit
}

.amplify-checkbox[data-disabled=true] {
    cursor: var(--amplify-components-checkbox-disabled-cursor)
}

.amplify-checkbox__button {
    position: var(--amplify-components-checkbox-button-position);
    align-items: var(--amplify-components-checkbox-button-align-items);
    justify-content: var(--amplify-components-checkbox-button-justify-content);
    color: var(--amplify-components-checkbox-button-color)
}

.amplify-checkbox__button:before {
    content: "";
    display: inline-block;
    position: absolute;
    box-sizing: border-box;
    width: var(--amplify-components-checkbox-button-before-width);
    height: var(--amplify-components-checkbox-button-before-height);
    border-width: var(--amplify-components-checkbox-button-before-border-width);
    border-radius: var(--amplify-components-checkbox-button-before-border-radius);
    border-style: var(--amplify-components-checkbox-button-before-border-style);
    border-color: var(--amplify-components-checkbox-button-before-border-color)
}

.amplify-checkbox__button[data-focus=true]:before {
    outline-color: var(--amplify-components-checkbox-button-focus-outline-color);
    outline-style: var(--amplify-components-checkbox-button-focus-outline-style);
    outline-width: var(--amplify-components-checkbox-button-focus-outline-width);
    outline-offset: var(--amplify-components-checkbox-button-focus-outline-offset);
    border-color: var(--amplify-components-checkbox-button-focus-border-color);
    box-shadow: var(--amplify-components-checkbox-button-focus-box-shadow)
}

.amplify-checkbox__button[data-error=true]:before {
    border-color: var(--amplify-components-checkbox-button-error-border-color)
}

.amplify-checkbox__button[data-error=true][data-focus=true]:before {
    border-color: var(--amplify-components-checkbox-button-error-focus-border-color);
    box-shadow: var(--amplify-components-checkbox-button-error-focus-box-shadow)
}

.amplify-checkbox__button[data-disabled=true]:before {
    border-color: var(--amplify-components-checkbox-button-disabled-border-color)
}

.amplify-checkbox__icon {
    background-color: var(--amplify-components-checkbox-icon-background-color);
    opacity: var(--amplify-components-checkbox-icon-opacity);
    transform: var(--amplify-components-checkbox-icon-transform);
    border-radius: var(--amplify-components-checkbox-icon-border-radius);
    transition-property: var(--amplify-components-checkbox-icon-transition-property);
    transition-duration: var(--amplify-components-checkbox-icon-transition-duration);
    transition-timing-function: var(--amplify-components-checkbox-icon-transition-timing-function)
}

.amplify-checkbox__icon[data-checked=true] {
    opacity: var(--amplify-components-checkbox-icon-checked-opacity);
    transform: var(--amplify-components-checkbox-icon-checked-transform)
}

.amplify-checkbox__icon[data-checked=true][data-disabled=true] {
    background-color: var(--amplify-components-checkbox-icon-checked-disabled-background-color)
}

.amplify-checkbox__label[data-disabled=true] {
    color: var(--amplify-components-checkbox-label-disabled-color)
}

.amplify-checkboxfield {
    align-content: var(--amplify-components-checkboxfield-align-content);
    align-items: var(--amplify-components-checkboxfield-align-items);
    flex-direction: var(--amplify-components-checkboxfield-flex-direction);
    justify-content: var(--amplify-components-checkboxfield-justify-content)
}

.amplify-countrycodeselect {
    height: var(--amplify-components-countrycodeselect-height)
}

.amplify-divider {
    border-color: var(--amplify-components-divider-border-color);
    border-style: var(--amplify-components-divider-border-style);
    border-width: 0;
    margin: 0;
    opacity: var(--amplify-components-divider-opacity);
    padding: 0;
    display: block
}

.amplify-divider[aria-orientation=horizontal] {
    width: 100%;
    border-bottom-width: var(--amplify-components-divider-border-width)
}

.amplify-divider[aria-orientation=horizontal][data-size=small] {
    border-bottom-width: var(--amplify-components-divider-small-border-width)
}

.amplify-divider[aria-orientation=horizontal][data-size=large] {
    border-bottom-width: var(--amplify-components-divider-large-border-width)
}

.amplify-divider[aria-orientation=vertical] {
    border-left-width: var(--amplify-components-divider-border-width)
}

.amplify-divider[aria-orientation=vertical][data-size=small] {
    border-left-width: var(--amplify-components-divider-small-border-width)
}

.amplify-divider[aria-orientation=vertical][data-size=large] {
    border-left-width: var(--amplify-components-divider-large-border-width)
}

.amplify-expander {
    display: block;
    background-color: var(--amplify-components-expander-background-color);
    border-radius: var(--amplify-components-expander-border-radius);
    box-shadow: var(--amplify-components-expander-box-shadow);
    width: var(--amplify-components-expander-width)
}

.amplify-expander__item {
    display: block;
    overflow: hidden;
    box-shadow: var(--amplify-components-expander-item-box-shadow)
}

.amplify-expander__item:first-of-type {
    border-start-start-radius: var(--amplify-components-expander-item-border-start-start-radius);
    border-start-end-radius: var(--amplify-components-expander-item-border-start-end-radius)
}

@supports not (border-start-start-radius: var(--amplify-components-expander-item-border-start-start-radius)) {
    .amplify-expander__item:first-of-type {
        border-top-left-radius: var(--amplify-components-expander-item-border-top-left-radius);
        border-top-right-radius: var(--amplify-components-expander-item-border-top-right-radius)
    }
}

.amplify-expander__item:last-of-type {
    box-shadow: none;
    border-end-start-radius: var(--amplify-components-expander-item-border-end-start-radius);
    border-end-end-radius: var(--amplify-components-expander-item-border-end-end-radius)
}

@supports not (border-end-start-radius: var(--amplify-components-expander-item-border-end-start-radius)) {
    .amplify-expander__item:last-of-type {
        border-bottom-left-radius: var(--amplify-components-expander-item-border-bottom-left-radius);
        border-bottom-right-radius: var(--amplify-components-expander-item-border-bottom-right-radius)
    }
}

.amplify-expander__item:focus-within {
    box-shadow: var(--amplify-components-expander-item-focus-box-shadow)
}

.amplify-expander__header {
    display: flex;
    box-shadow: var(--amplify-components-expander-header-box-shadow)
}

.amplify-expander__trigger {
    flex: 1;
    display: flex;
    min-height: var(--amplify-components-expander-trigger-min-height);
    -webkit-padding-start: var(--amplify-components-expander-trigger-padding-inline-start);
    padding-inline-start: var(--amplify-components-expander-trigger-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-expander-trigger-padding-inline-end);
    padding-inline-end: var(--amplify-components-expander-trigger-padding-inline-end);
    align-items: var(--amplify-components-expander-trigger-align-items);
    justify-content: var(--amplify-components-expander-trigger-justify-content)
}

.amplify-expander__trigger:hover {
    background-color: var(--amplify-components-expander-trigger-hover-background-color)
}

.amplify-expander__content {
    display: block;
    -webkit-padding-start: var(--amplify-components-expander-content-padding-inline-start);
    padding-inline-start: var(--amplify-components-expander-content-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-expander-content-padding-inline-end);
    padding-inline-end: var(--amplify-components-expander-content-padding-inline-end)
}

.amplify-expander__content[data-state=open] {
    -webkit-animation-name: amplify-expander-slide-down;
    animation-name: amplify-expander-slide-down;
    -webkit-animation-duration: var(--amplify-components-expander-content-open-animation-duration);
    animation-duration: var(--amplify-components-expander-content-open-animation-duration);
    -webkit-animation-timing-function: var(--amplify-components-expander-content-open-animation-timing-function);
    animation-timing-function: var(--amplify-components-expander-content-open-animation-timing-function)
}

.amplify-expander__content[data-state=closed] {
    -webkit-animation-name: amplify-expander-slide-up;
    animation-name: amplify-expander-slide-up;
    -webkit-animation-duration: var(--amplify-components-expander-content-closed-animation-duration);
    animation-duration: var(--amplify-components-expander-content-closed-animation-duration);
    -webkit-animation-timing-function: var(--amplify-components-expander-content-closed-animation-timing-function);
    animation-timing-function: var(--amplify-components-expander-content-closed-animation-timing-function)
}

.amplify-expander__content__text {
    display: block;
    color: var(--amplify-components-expander-content-text-color);
    -webkit-padding-before: var(--amplify-components-expander-content-text-padding-block-start);
    padding-block-start: var(--amplify-components-expander-content-text-padding-block-start);
    -webkit-padding-after: var(--amplify-components-expander-content-text-padding-block-end);
    padding-block-end: var(--amplify-components-expander-content-text-padding-block-end)
}

.amplify-expander__icon {
    transition-property: transform;
    transition-duration: var(--amplify-components-expander-icon-transition-duration);
    transition-timing-function: var(--amplify-components-expander-icon-transition-timing-function)
}

[data-state=open] .amplify-expander__icon {
    transform: rotate(180deg)
}

@-webkit-keyframes amplify-expander-slide-down {
    0% {
        height: 0
    }

    to {
        height: auto
    }
}

@keyframes amplify-expander-slide-down {
    0% {
        height: 0
    }

    to {
        height: auto
    }
}

@-webkit-keyframes amplify-expander-slide-up {
    0% {
        height: auto
    }

    to {
        height: 0
    }
}

@keyframes amplify-expander-slide-up {
    0% {
        height: auto
    }

    to {
        height: 0
    }
}

.amplify-select,
.amplify-input,
.amplify-textarea {
    box-sizing: border-box;
    color: var(--amplify-components-fieldcontrol-color);
    font-size: var(--amplify-components-fieldcontrol-font-size);
    line-height: var(--amplify-components-fieldcontrol-line-height);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-padding-block-start);
    padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-padding-block-end);
    padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-padding-inline-end);
    padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end);
    transition: all var(--amplify-components-fieldcontrol-transition-duration);
    width: 100%;
    border-color: var(--amplify-components-fieldcontrol-border-color);
    border-radius: var(--amplify-components-fieldcontrol-border-radius);
    border-style: var(--amplify-components-fieldcontrol-border-style);
    border-width: var(--amplify-components-fieldcontrol-border-width);
    outline-color: var(--amplify-components-fieldcontrol-outline-color);
    outline-style: var(--amplify-components-fieldcontrol-outline-style);
    outline-width: var(--amplify-components-fieldcontrol-outline-width);
    outline-offset: var(--amplify-components-fieldcontrol-outline-offset)
}

.amplify-select:focus,
.amplify-input:focus,
.amplify-textarea:focus {
    border-color: var(--amplify-components-fieldcontrol-focus-border-color);
    box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow)
}

[data-size=small].amplify-select,
[data-size=small].amplify-input,
[data-size=small].amplify-textarea {
    font-size: var(--amplify-components-fieldcontrol-small-font-size);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-small-padding-block-start);
    padding-block-start: var(--amplify-components-fieldcontrol-small-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-small-padding-block-end);
    padding-block-end: var(--amplify-components-fieldcontrol-small-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
    padding-inline-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
    padding-inline-end: var(--amplify-components-fieldcontrol-small-padding-inline-end)
}

[data-size=large].amplify-select,
[data-size=large].amplify-input,
[data-size=large].amplify-textarea {
    font-size: var(--amplify-components-fieldcontrol-large-font-size);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-large-padding-block-start);
    padding-block-start: var(--amplify-components-fieldcontrol-large-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-large-padding-block-end);
    padding-block-end: var(--amplify-components-fieldcontrol-large-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
    padding-inline-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
    padding-inline-end: var(--amplify-components-fieldcontrol-large-padding-inline-end)
}

[aria-invalid=true].amplify-select,
[aria-invalid=true].amplify-input,
[aria-invalid=true].amplify-textarea {
    border-color: var(--amplify-components-fieldcontrol-error-border-color)
}

[aria-invalid=true].amplify-select:focus,
[aria-invalid=true].amplify-input:focus,
[aria-invalid=true].amplify-textarea:focus {
    border-color: var(--amplify-components-fieldcontrol-error-border-color);
    box-shadow: var(--amplify-components-fieldcontrol-error-focus-box-shadow)
}

[disabled].amplify-select,
[disabled].amplify-input,
[disabled].amplify-textarea {
    color: var(--amplify-components-fieldcontrol-disabled-color);
    cursor: var(--amplify-components-fieldcontrol-disabled-cursor);
    border-color: var(--amplify-components-fieldcontrol-disabled-border-color);
    background-color: var(--amplify-components-fieldcontrol-disabled-background-color)
}

[data-variation=quiet].amplify-select,
[data-variation=quiet].amplify-input,
[data-variation=quiet].amplify-textarea {
    -webkit-border-before: var(--amplify-components-fieldcontrol-quiet-border-block-start);
    border-block-start: var(--amplify-components-fieldcontrol-quiet-border-block-start);
    -webkit-border-start: var(--amplify-components-fieldcontrol-quiet-border-inline-start);
    border-inline-start: var(--amplify-components-fieldcontrol-quiet-border-inline-start);
    -webkit-border-end: var(--amplify-components-fieldcontrol-quiet-border-inline-end);
    border-inline-end: var(--amplify-components-fieldcontrol-quiet-border-inline-end);
    border-radius: var(--amplify-components-fieldcontrol-quiet-border-radius)
}

[data-variation=quiet].amplify-select:focus,
[data-variation=quiet].amplify-input:focus,
[data-variation=quiet].amplify-textarea:focus {
    border-block-end-color: var(--amplify-components-fieldcontrol-quiet-focus-border-block-end-color);
    box-shadow: var(--amplify-components-fieldcontrol-quiet-focus-box-shadow)
}

[data-variation=quiet][aria-invalid=true].amplify-select,
[data-variation=quiet][aria-invalid=true].amplify-input,
[data-variation=quiet][aria-invalid=true].amplify-textarea {
    border-block-end-color: var(--amplify-components-fieldcontrol-quiet-error-border-block-end-color)
}

[data-variation=quiet][aria-invalid=true].amplify-select:focus,
[data-variation=quiet][aria-invalid=true].amplify-input:focus,
[data-variation=quiet][aria-invalid=true].amplify-textarea:focus {
    box-shadow: var(--amplify-components-fieldcontrol-quiet-error-focus-box-shadow)
}

.amplify-field {
    font-size: var(--amplify-components-field-font-size);
    gap: var(--amplify-components-field-gap)
}

.amplify-field[data-size=small] {
    font-size: var(--amplify-components-field-small-font-size);
    gap: var(--amplify-components-field-small-gap)
}

.amplify-field[data-size=large] {
    font-size: var(--amplify-components-field-large-font-size);
    gap: var(--amplify-components-field-large-gap)
}

.amplify-label {
    color: var(--amplify-components-field-label-color)
}

.amplify-field-group__outer-end .amplify-select__wrapper .amplify-select,
.amplify-field-group__outer-end .amplify-field-group__control,
.amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child),
.amplify-field-group__outer-start .amplify-field-group__control:not(:first-child),
.amplify-field-group :not(:first-child) .amplify-input {
    border-start-start-radius: 0;
    border-end-start-radius: 0
}

@supports not (border-start-start-radius: 0) {

    .amplify-field-group__outer-end .amplify-select__wrapper .amplify-select,
    .amplify-field-group__outer-end .amplify-field-group__control,
    .amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child),
    .amplify-field-group__outer-start .amplify-field-group__control:not(:first-child),
    .amplify-field-group :not(:first-child) .amplify-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
}

.amplify-field-group__outer-end .amplify-select__wrapper:not(:last-child) .amplify-select,
.amplify-field-group__outer-end .amplify-field-group__control:not(:last-child),
.amplify-field-group__outer-start .amplify-select__wrapper .amplify-select,
.amplify-field-group__outer-start .amplify-field-group__control,
.amplify-field-group :not(:last-child) .amplify-input {
    border-end-end-radius: 0;
    border-start-end-radius: 0
}

@supports not (border-end-end-radius: 0) {

    .amplify-field-group__outer-end .amplify-select__wrapper:not(:last-child) .amplify-select,
    .amplify-field-group__outer-end .amplify-field-group__control:not(:last-child),
    .amplify-field-group__outer-start .amplify-select__wrapper .amplify-select,
    .amplify-field-group__outer-start .amplify-field-group__control,
    .amplify-field-group :not(:last-child) .amplify-input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0
    }
}

.amplify-field-group {
    gap: var(--amplify-components-fieldgroup-gap)
}

.amplify-field-group[data-orientation=horizontal] {
    flex-direction: row
}

.amplify-field-group[data-orientation=vertical] {
    flex-direction: column;
    align-items: var(--amplify-components-fieldgroup-vertical-align-items)
}

.amplify-field-group__outer-start,
.amplify-field-group__outer-end {
    display: flex;
    align-items: var(--amplify-components-fieldgroup-outer-align-items)
}

.amplify-field-group__outer-start .amplify-field-group__control,
.amplify-field-group__outer-end .amplify-field-group__control {
    height: 100%
}

.amplify-field-group__field-wrapper {
    position: relative;
    width: 100%
}

.amplify-field-group__field-wrapper[data-orientation=vertical] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.amplify-field-group__inner-end,
.amplify-field-group__inner-start {
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none
}

.amplify-field-group__inner-end .amplify-button,
.amplify-field-group__inner-start .amplify-button {
    pointer-events: all;
    height: 100%
}

.amplify-field-group__inner-end {
    right: 0;
    left: auto
}

.amplify-field-group__inner-start,
html[dir=rtl] .amplify-field-group__inner-end {
    right: auto;
    left: 0
}

html[dir=rtl] .amplify-field-group__inner-start {
    left: auto;
    right: 0
}

.amplify-field-group--has-inner-end .amplify-input {
    -webkit-padding-end: calc(var(--amplify-components-fieldcontrol-padding-inline-end) * 3);
    padding-inline-end: calc(var(--amplify-components-fieldcontrol-padding-inline-end) * 3)
}

.amplify-field-group--has-inner-start .amplify-input {
    -webkit-padding-start: calc(var(--amplify-components-fieldcontrol-padding-inline-start) * 3);
    padding-inline-start: calc(var(--amplify-components-fieldcontrol-padding-inline-start) * 3)
}

.amplify-field-group__icon:not(.amplify-field-group__icon-button) {
    display: flex;
    -webkit-padding-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-padding-inline-start);
    padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-start);
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.amplify-menu-trigger {
    display: flex;
    justify-content: center;
    align-items: center
}

.amplify-menu-content {
    background-color: var(--amplify-components-menu-background-color);
    border-radius: var(--amplify-components-menu-border-radius);
    box-shadow: var(--amplify-components-menu-box-shadow);
    flex-direction: var(--amplify-components-menu-flex-direction);
    gap: var(--amplify-components-menu-gap);
    min-width: var(--amplify-components-menu-min-width);
    max-width: var(--amplify-components-menu-max-width)
}

.amplify-menu-content__item {
    min-height: var(--amplify-components-menu-item-min-width);
    -webkit-padding-start: var(--amplify-components-menu-item-padding-inline-start);
    padding-inline-start: var(--amplify-components-menu-item-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-menu-item-padding-inline-end);
    padding-inline-end: var(--amplify-components-menu-item-padding-inline-end)
}

.amplify-menu-content__item:not(:first-child):not(:last-child) {
    border-radius: 0
}

.amplify-menu-content__item:first-child {
    border-end-end-radius: 0;
    border-end-start-radius: 0
}

@supports not (border-end-end-radius: 0) {
    .amplify-menu-content__item:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }
}

.amplify-menu-content__item:last-child {
    border-start-end-radius: 0;
    border-start-start-radius: 0
}

@supports not (border-end-end-radius: 0) {
    .amplify-menu-content__item:last-child {
        border-top-right-radius: 0;
        border-top-left-radius: 0
    }
}

.amplify-pagination__item-current,
.amplify-pagination__item-button[data-variation=link] {
    height: var(--amplify-components-pagination-item-shared-height);
    min-width: var(--amplify-components-pagination-item-shared-min-width);
    border-radius: var(--amplify-components-pagination-item-shared-border-radius)
}

.amplify-pagination {
    list-style-type: none
}

.amplify-pagination li {
    margin-left: var(--amplify-components-pagination-item-container-margin-left);
    margin-right: var(--amplify-components-pagination-item-container-margin-right)
}

.amplify-pagination__item-button[data-variation=link] {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: initial;
    color: var(--amplify-components-pagination-button-color);
    -webkit-padding-start: var(--amplify-components-pagination-button-padding-inline-start);
    padding-inline-start: var(--amplify-components-pagination-button-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-pagination-button-padding-inline-end);
    padding-inline-end: var(--amplify-components-pagination-button-padding-inline-end);
    transition-property: var(--amplify-components-pagination-button-transition-property);
    transition-duration: var(--amplify-components-pagination-button-transition-duration)
}

.amplify-pagination__item-button[data-variation=link]:hover {
    text-decoration: none;
    color: var(--amplify-components-pagination-button-hover-color);
    background-color: var(--amplify-components-pagination-button-hover-background-color)
}

.amplify-pagination__item-button[data-variation=link][disabled] {
    color: var(--amplify-components-pagination-button-disabled-color);
    pointer-events: none
}

.amplify-pagination__item-current {
    align-items: var(--amplify-components-pagination-current-align-items);
    justify-content: var(--amplify-components-pagination-current-justify-content);
    color: var(--amplify-components-pagination-current-color);
    font-size: var(--amplify-components-pagination-current-font-size);
    background-color: var(--amplify-components-pagination-current-background-color)
}

.amplify-pagination__item-ellipsis {
    align-items: var(--amplify-components-pagination-ellipsis-align-items);
    justify-content: var(--amplify-components-pagination-ellipsis-justify-content);
    -webkit-padding-start: var(--amplify-components-pagination-ellipsis-padding-inline-start);
    padding-inline-start: var(--amplify-components-pagination-ellipsis-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-pagination-ellipsis-padding-inline-end);
    padding-inline-end: var(--amplify-components-pagination-ellipsis-padding-inline-end)
}

.amplify-phonenumberfield select:not(:focus) {
    border-right: none
}

.amplify-rating {
    display: inline-flex;
    position: relative;
    text-align: left;
    font-size: var(--amplify-components-rating-default-size);
    line-height: var(--amplify-components-rating-default-size)
}

.amplify-rating[data-size=small] {
    font-size: var(--amplify-components-rating-small-size);
    line-height: var(--amplify-components-rating-small-size)
}

.amplify-rating[data-size=large] {
    font-size: var(--amplify-components-rating-large-size);
    line-height: var(--amplify-components-rating-large-size)
}

.amplify-rating .amplify-icon {
    font-size: unset;
    line-height: unset;
    height: 1em
}

.amplify-rating-filled {
    fill: currentColor;
    display: inline-block;
    flex-shrink: 0;
    width: 1em;
    height: 1em
}

.amplify-rating-icon-container {
    position: relative;
    height: 1em;
    width: 1em
}

.amplify-rating-label {
    position: absolute;
    overflow: hidden;
    height: 1em;
    width: 1em
}

.amplify-rating-icon {
    width: 1em;
    height: 1em
}

.amplify-rating-icon-filled {
    color: var(--amplify-components-rating-filled-color)
}

.amplify-rating-icon-empty {
    color: var(--amplify-components-rating-empty-color)
}

.amplify-radio {
    align-items: var(--amplify-components-radio-align-items);
    justify-content: var(--amplify-components-radio-justify-content);
    gap: inherit
}

.amplify-radio[data-disabled=true] {
    cursor: var(--amplify-components-radio-disabled-cursor)
}

.amplify-radio__button {
    align-items: var(--amplify-components-radio-button-align-items);
    justify-content: var(--amplify-components-radio-button-justify-content);
    width: var(--amplify-components-radio-button-width);
    height: var(--amplify-components-radio-button-height);
    padding: var(--amplify-components-radio-button-padding);
    box-sizing: var(--amplify-components-radio-button-box-sizing);
    border-width: var(--amplify-components-radio-button-border-width);
    border-style: var(--amplify-components-radio-button-border-style);
    border-radius: var(--amplify-components-radio-button-border-radius);
    border-color: var(--amplify-components-radio-button-border-color);
    color: var(--amplify-components-radio-button-color);
    background-color: var(--amplify-components-radio-button-background-color);
    transition-property: var(--amplify-components-radio-button-transition-property);
    transition-duration: var(--amplify-components-radio-button-transition-duration);
    outline-color: var(--amplify-components-radio-button-outline-color);
    outline-style: var(--amplify-components-radio-button-outline-style);
    outline-width: var(--amplify-components-radio-button-outline-width);
    outline-offset: var(--amplify-components-radio-button-outline-offset)
}

.amplify-radio__button:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: var(--amplify-components-radio-button-before-border-radius);
    background-color: currentColor;
    border-radius: 50%
}

.amplify-radio__button[data-size=small] {
    width: var(--amplify-components-radio-button-small-width);
    height: var(--amplify-components-radio-button-small-height)
}

.amplify-radio__button[data-size=large] {
    width: var(--amplify-components-radio-button-large-width);
    height: var(--amplify-components-radio-button-large-height)
}

.amplify-radio__input:checked+.amplify-radio__button {
    color: var(--amplify-components-radio-button-checked-color)
}

.amplify-radio__input:checked:disabled+.amplify-radio__button {
    color: var(--amplify-components-radio-button-checked-disabled-color)
}

.amplify-radio__input:focus+.amplify-radio__button {
    border-color: var(--amplify-components-radio-button-focus-border-color);
    box-shadow: var(--amplify-components-radio-button-focus-box-shadow)
}

.amplify-radio__input[aria-invalid=true]+.amplify-radio__button {
    border-color: var(--amplify-components-radio-button-error-border-color)
}

.amplify-radio__input[aria-invalid=true]:focus+.amplify-radio__button {
    box-shadow: var(--amplify-components-radio-button-error-focus-box-shadow)
}

.amplify-radio__input:disabled+.amplify-radio__button {
    border-color: var(--amplify-components-radio-button-disabled-border-color);
    background-color: var(--amplify-components-radio-button-disabled-background-color)
}

.amplify-radio__label[data-disabled=true] {
    color: var(--amplify-components-radio-label-disabled-color)
}

.amplify-radiogroup {
    gap: inherit;
    flex-direction: inherit
}

.amplify-radiogroupfield {
    flex-direction: column
}

.amplify-select__wrapper {
    flex: var(--amplify-components-select-wrapper-flex);
    display: var(--amplify-components-select-wrapper-display);
    position: var(--amplify-components-select-wrapper-position);
    cursor: var(--amplify-components-select-wrapper-cursor)
}

.amplify-select__icon-wrapper {
    align-items: var(--amplify-components-select-icon-wrapper-align-items);
    position: var(--amplify-components-select-icon-wrapper-position);
    top: var(--amplify-components-select-icon-wrapper-top);
    right: var(--amplify-components-select-icon-wrapper-right);
    transform: var(--amplify-components-select-icon-wrapper-transform);
    pointer-events: var(--amplify-components-select-icon-wrapper-pointer-events)
}

.amplify-select {
    -webkit-padding-end: var(--amplify-components-select-padding-inline-end);
    padding-inline-end: var(--amplify-components-select-padding-inline-end);
    min-width: var(--amplify-components-select-min-width);
    white-space: var(--amplify-components-select-white-space)
}

@-moz-document url-prefix() {
    .amplify-select option {
        background-color: var(--amplify-components-select-option-background-color)
    }
}

.amplify-select[data-size=small] {
    min-width: var(--amplify-components-select-small-min-width)
}

.amplify-select[data-size=large] {
    min-width: var(--amplify-components-select-large-min-width)
}

.amplify-selectfield {
    flex-direction: var(--amplify-components-selectfield-flex-direction)
}

.amplify-sliderfield {
    flex-direction: column
}

.amplify-sliderfield__label {
    display: flex;
    justify-content: space-between
}

.amplify-sliderfield__root {
    position: relative;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: none;
    box-sizing: content-box;
    padding-block: var(--amplify-components-sliderfield-padding-block)
}

.amplify-sliderfield__root[data-disabled] {
    cursor: not-allowed
}

.amplify-sliderfield__root[data-orientation=horizontal] {
    height: var(--amplify-components-sliderfield-thumb-height)
}

.amplify-sliderfield__root[data-orientation=vertical] {
    flex-direction: column;
    width: var(--amplify-components-sliderfield-thumb-width)
}

[data-size=large] .amplify-sliderfield__root[data-orientation=horizontal] {
    height: var(--amplify-components-sliderfield-large-thumb-height)
}

[data-size=large] .amplify-sliderfield__root[data-orientation=vertical] {
    width: var(--amplify-components-sliderfield-large-thumb-height)
}

[data-size=small] .amplify-sliderfield__root[data-orientation=horizontal] {
    height: var(--amplify-components-sliderfield-small-thumb-height)
}

[data-size=small] .amplify-sliderfield__root[data-orientation=vertical] {
    width: var(--amplify-components-sliderfield-small-thumb-height)
}

.amplify-sliderfield__track {
    position: relative;
    flex-grow: 1;
    border-radius: var(--amplify-components-sliderfield-track-border-radius);
    background-color: var(--amplify-components-sliderfield-track-background-color)
}

.amplify-sliderfield__track[data-orientation=horizontal] {
    height: var(--amplify-components-sliderfield-track-height);
    min-width: var(--amplify-components-sliderfield-track-min-width)
}

.amplify-sliderfield__track[data-orientation=vertical] {
    width: var(--amplify-components-sliderfield-track-height);
    min-height: var(--amplify-components-sliderfield-track-min-width)
}

[data-size=large] .amplify-sliderfield__track[data-orientation=vertical] {
    width: var(--amplify-components-sliderfield-large-track-height)
}

[data-size=large] .amplify-sliderfield__track[data-orientation=horizontal] {
    height: var(--amplify-components-sliderfield-large-track-height)
}

[data-size=small] .amplify-sliderfield__track[data-orientation=vertical] {
    width: var(--amplify-components-sliderfield-small-track-height)
}

[data-size=small] .amplify-sliderfield__track[data-orientation=horizontal] {
    height: var(--amplify-components-sliderfield-small-track-height)
}

.amplify-sliderfield__range {
    position: absolute;
    border-radius: var(--amplify-components-sliderfield-range-border-radius);
    background-color: var(--amplify-components-sliderfield-range-background-color)
}

.amplify-sliderfield__range[data-disabled] {
    background-color: var(--amplify-components-sliderfield-range-disabled-background-color)
}

.amplify-sliderfield__range[data-orientation=horizontal] {
    height: 100%
}

.amplify-sliderfield__range[data-orientation=vertical] {
    width: 100%
}

.amplify-sliderfield__thumb {
    display: block;
    width: var(--amplify-components-sliderfield-thumb-width);
    height: var(--amplify-components-sliderfield-thumb-height);
    background-color: var(--amplify-components-sliderfield-thumb-background-color);
    box-shadow: var(--amplify-components-sliderfield-thumb-box-shadow);
    border-radius: var(--amplify-components-sliderfield-thumb-border-radius);
    border-width: var(--amplify-components-sliderfield-thumb-border-width);
    border-color: var(--amplify-components-sliderfield-thumb-border-color);
    border-style: var(--amplify-components-sliderfield-thumb-border-style)
}

.amplify-sliderfield__thumb:hover {
    background-color: var(--amplify-components-sliderfield-thumb-hover-background-color);
    border-color: var(--amplify-components-sliderfield-thumb-hover-border-color)
}

.amplify-sliderfield__thumb:focus {
    box-shadow: var(--amplify-components-sliderfield-thumb-focus-box-shadow)
}

.amplify-sliderfield__thumb[data-disabled] {
    background-color: var(--amplify-components-sliderfield-thumb-disabled-background-color);
    border-color: var(--amplify-components-sliderfield-thumb-disabled-border-color);
    box-shadow: var(--amplify-components-sliderfield-thumb-disabled-box-shadow)
}

[data-size=large] .amplify-sliderfield__thumb {
    width: var(--amplify-components-sliderfield-large-thumb-width);
    height: var(--amplify-components-sliderfield-large-thumb-height)
}

[data-size=small] .amplify-sliderfield__thumb {
    width: var(--amplify-components-sliderfield-small-thumb-width);
    height: var(--amplify-components-sliderfield-small-thumb-height)
}

.amplify-stepperfield {
    flex-direction: var(--amplify-components-stepperfield-flex-direction)
}

.amplify-stepperfield__button--decrease[data-invalid=true] {
    -webkit-border-end: none;
    border-inline-end: none
}

[data-variation=quiet] .amplify-stepperfield__button--decrease {
    border-width: 0 0 var(--amplify-components-button-border-width) 0;
    border-radius: 0
}

.amplify-stepperfield__button--increase[data-invalid=true] {
    -webkit-border-start: none;
    border-inline-start: none
}

[data-variation=quiet] .amplify-stepperfield__button--increase {
    border-width: 0 0 var(--amplify-components-button-border-width) 0;
    border-radius: 0
}

.amplify-stepperfield__input {
    -moz-appearance: textfield;
    text-align: var(--amplify-components-stepperfield-input-text-align)
}

.amplify-stepperfield__input::-webkit-outer-spin-button,
.amplify-stepperfield__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.amplify-stepperfield__input:not(:focus, [aria-invalid=true]) {
    -webkit-border-start: none;
    border-inline-start: none;
    -webkit-border-end: none;
    border-inline-end: none
}

.amplify-switchfield {
    display: inline-block
}

.amplify-switchfield[data-size=small] {
    font-size: var(--amplify-components-switchfield-small-font-size)
}

.amplify-switchfield[data-size=large] {
    font-size: var(--amplify-components-switchfield-large-font-size)
}

.amplify-switch__wrapper {
    display: inline-flex;
    align-items: center
}

.amplify-switch__wrapper[data-label-position=start] {
    flex-direction: row
}

.amplify-switch__wrapper[data-label-position=end] {
    flex-direction: row-reverse
}

.amplify-switch__wrapper[data-label-position=top] {
    flex-direction: column
}

.amplify-switch__wrapper[data-label-position=bottom] {
    flex-direction: column-reverse
}

.amplify-switch-track {
    display: inline-flex;
    justify-content: flex-start;
    box-sizing: content-box;
    border-radius: var(--amplify-components-switchfield-track-border-radius);
    padding: var(--amplify-components-switchfield-track-padding);
    width: var(--amplify-components-switchfield-track-width);
    height: var(--amplify-components-switchfield-track-height);
    transition-duration: var(--amplify-components-switchfield-track-transition-duration);
    background-color: var(--amplify-components-switchfield-track-background-color)
}

.amplify-switch-track[data-checked=true] {
    background-color: var(--amplify-components-switchfield-track-checked-background-color)
}

.amplify-switch-track[data-disabled=true] {
    opacity: var(--amplify-components-switchfield-disabled-opacity);
    cursor: not-allowed
}

.amplify-switch-track[data-focused=true] {
    box-shadow: var(--amplify-components-switchfield-focused-shadow)
}

.amplify-switch-thumb {
    background-color: var(--amplify-components-switchfield-thumb-background-color);
    transition-duration: var(--amplify-components-switchfield-thumb-transition-duration);
    border-radius: var(--amplify-components-switchfield-thumb-border-radius);
    width: var(--amplify-components-switchfield-thumb-width);
    height: var(--amplify-components-switchfield-thumb-width);
    border-color: var(--amplify-components-switchfield-thumb-border-color);
    overflow-wrap: break-word
}

.amplify-switch-thumb[data-checked=true] {
    transform: var(--amplify-components-switchfield-thumb-checked-transform)
}

.amplify-switch-thumb[data-disabled=true] {
    cursor: not-allowed
}

.amplify-switch-label {
    padding: var(--amplify-components-switchfield-label-padding);
    cursor: pointer
}

.amplify-table {
    border-collapse: var(--amplify-components-table-border-collapse);
    display: var(--amplify-components-table-display);
    width: var(--amplify-components-table-width)
}

.amplify-table .amplify-table__caption {
    caption-side: var(--amplify-components-table-caption-caption-side);
    color: var(--amplify-components-table-caption-color);
    display: var(--amplify-components-table-caption-display);
    font-size: var(--amplify-components-table-caption-font-size);
    text-align: var(--amplify-components-table-caption-text-align);
    word-break: var(--amplify-components-table-caption-word-break)
}

.amplify-table .amplify-table__head {
    display: var(--amplify-components-table-head-display);
    vertical-align: var(--amplify-components-table-head-vertical-align)
}

.amplify-table .amplify-table__body {
    display: var(--amplify-components-table-body-display);
    vertical-align: var(--amplify-components-table-body-vertical-align)
}

.amplify-table .amplify-table__foot {
    display: var(--amplify-components-table-foot-display);
    vertical-align: var(--amplify-components-table-foot-vertical-align)
}

.amplify-table .amplify-table__row {
    display: var(--amplify-components-table-row-display);
    vertical-align: var(--amplify-components-table-row-vertical-align)
}

.amplify-table .amplify-table__th {
    border-color: var(--amplify-components-table-header-border-color);
    border-style: var(--amplify-components-table-header-border-style);
    border-width: var(--amplify-components-table-header-border-width);
    color: var(--amplify-components-table-header-color);
    display: var(--amplify-components-table-header-display);
    font-size: var(--amplify-components-table-header-font-size);
    font-weight: var(--amplify-components-table-header-font-weight);
    padding: var(--amplify-components-table-header-padding);
    vertical-align: var(--amplify-components-table-header-vertical-align)
}

.amplify-table .amplify-table__td {
    border-color: var(--amplify-components-table-data-border-color);
    border-style: var(--amplify-components-table-data-border-style);
    border-width: var(--amplify-components-table-data-border-width);
    color: var(--amplify-components-table-data-color);
    display: var(--amplify-components-table-data-display);
    font-size: var(--amplify-components-table-data-font-size);
    font-weight: var(--amplify-components-table-data-font-weight);
    padding: var(--amplify-components-table-data-padding);
    vertical-align: var(--amplify-components-table-data-vertical-align)
}

.amplify-table:not([data-variation=bordered]) .amplify-table__td:not(:first-child),
.amplify-table:not([data-variation=bordered]) .amplify-table__th:not(:first-child) {
    border-left: none
}

.amplify-table:not([data-variation=bordered]) .amplify-table__td:not(:last-child),
.amplify-table:not([data-variation=bordered]) .amplify-table__th:not(:last-child) {
    border-right: none
}

.amplify-table[data-variation=striped] .amplify-table__row:not(.amplify-table__head *):nth-child(odd) {
    background-color: var(--amplify-components-table-row-striped-background-color)
}

.amplify-table[data-highlightonhover=true] .amplify-table__row:not(.amplify-table__head *):hover {
    background-color: var(--amplify-components-table-row-hover-background-color)
}

.amplify-table[data-size=small] .amplify-table__caption {
    font-size: var(--amplify-components-table-caption-small-font-size)
}

.amplify-table[data-size=small] .amplify-table__th {
    font-size: var(--amplify-components-table-header-small-font-size);
    padding: var(--amplify-components-table-header-small-padding)
}

.amplify-table[data-size=small] .amplify-table__td {
    font-size: var(--amplify-components-table-data-small-font-size);
    padding: var(--amplify-components-table-data-small-padding)
}

.amplify-table[data-size=large] .amplify-table__caption {
    font-size: var(--amplify-components-table-caption-large-font-size)
}

.amplify-table[data-size=large] .amplify-table__th {
    font-size: var(--amplify-components-table-header-large-font-size);
    padding: var(--amplify-components-table-header-large-padding)
}

.amplify-table[data-size=large] .amplify-table__td {
    font-size: var(--amplify-components-table-data-large-font-size);
    padding: var(--amplify-components-table-data-large-padding)
}

.amplify-tabs {
    background-color: var(--amplify-components-tabs-background-color);
    box-shadow: var(--amplify-components-tabs-box-shadow);
    border-width: 0 0 var(--amplify-components-tabs-border-width) 0;
    border-style: var(--amplify-components-tabs-border-style);
    border-color: var(--amplify-components-tabs-border-color);
    gap: var(--amplify-components-tabs-gap)
}

.amplify-tabs[data-indicator-position=top] {
    border-width: var(--amplify-components-tabs-border-width) 0 0 0
}

.amplify-tabs-item {
    background-color: var(--amplify-components-tabs-item-background-color);
    box-sizing: border-box;
    color: var(--amplify-components-tabs-item-color);
    font-size: var(--amplify-components-tabs-item-font-size);
    font-weight: var(--amplify-components-tabs-item-font-weight);
    padding: var(--amplify-components-tabs-item-padding-vertical) var(--amplify-components-tabs-item-padding-horizontal);
    text-align: var(--amplify-components-tabs-item-text-align);
    transition: all var(--amplify-components-tabs-item-transition-duration);
    border-width: 0 0 var(--amplify-components-tabs-item-border-width) 0;
    border-style: var(--amplify-components-tabs-item-border-style);
    border-color: var(--amplify-components-tabs-item-border-color);
    margin-bottom: calc(-1 * var(--amplify-components-tabs-item-border-width))
}

[data-indicator-position=top]>.amplify-tabs-item {
    border-width: var(--amplify-components-tabs-border-width) 0 0 0;
    margin-top: calc(-1 * var(--amplify-components-tabs-item-border-width));
    margin-bottom: 0
}

.amplify-tabs-item[data-state=active] {
    color: var(--amplify-components-tabs-item-active-color);
    border-color: var(--amplify-components-tabs-item-active-border-color);
    background-color: var(--amplify-components-tabs-item-active-background-color);
    transition-property: none
}

.amplify-tabs-item:hover {
    color: var(--amplify-components-tabs-item-hover-color);
    cursor: pointer
}

.amplify-tabs-item:focus {
    color: var(--amplify-components-tabs-item-focus-color)
}

.amplify-tabs-item:focus-visible {
    outline: var(--amplify-components-tabs-item-border-width) var(--amplify-components-tabs-item-border-style) var(--amplify-components-tabs-item-border-color)
}

.amplify-tabs-item:active {
    color: var(--amplify-components-tabs-item-active-color)
}

.amplify-tabs-item[data-disabled] {
    background-color: var(--amplify-components-tabs-item-disabled-background-color);
    color: var(--amplify-components-tabs-item-disabled-color);
    cursor: not-allowed
}

.amplify-tabs-item[data-spacing=equal] {
    flex: 1 1 0
}

.amplify-tabs-item[data-spacing=relative] {
    flex-grow: 1
}

.amplify-textfield {
    flex-direction: column
}

.amplify-togglebutton[data-variation=primary],
.amplify-togglebutton:hover,
.amplify-togglebutton {
    border-color: var(--amplify-components-togglebutton-border-color);
    color: var(--amplify-components-togglebutton-color)
}

.amplify-togglebutton:hover {
    background-color: var(--amplify-components-togglebutton-hover-background-color)
}

.amplify-togglebutton:focus {
    border-color: var(--amplify-components-togglebutton-focus-border-color);
    color: var(--amplify-components-togglebutton-focus-color)
}

.amplify-togglebutton:active {
    background-color: var(--amplify-components-togglebutton-active-background-color)
}

.amplify-togglebutton:disabled {
    background-color: var(--amplify-components-togglebutton-disabled-background-color);
    border-color: var(--amplify-components-togglebutton-disabled-border-color);
    color: var(--amplify-components-togglebutton-disabled-color)
}

.amplify-togglebutton[aria-pressed=true] {
    background-color: var(--amplify-components-togglebutton-pressed-background-color);
    color: var(--amplify-components-togglebutton-pressed-color)
}

.amplify-togglebutton[aria-pressed=true]:hover:not(:disabled) {
    background-color: var(--amplify-components-togglebutton-pressed-hover-background-color)
}

.amplify-togglebutton[data-variation=primary] {
    background-color: var(--amplify-components-togglebutton-primary-background-color);
    border-width: var(--amplify-components-togglebutton-primary-border-width)
}

.amplify-togglebutton[data-variation=primary]:focus {
    background-color: var(--amplify-components-togglebutton-primary-focus-background-color);
    border-color: var(--amplify-components-togglebutton-primary-focus-border-color);
    color: var(--amplify-components-togglebutton-primary-focus-color);
    box-shadow: var(--amplify-components-togglebutton-primary-focus-box-shadow)
}

.amplify-togglebutton[data-variation=primary]:hover {
    background-color: var(--amplify-components-togglebutton-primary-hover-background-color);
    color: var(--amplify-components-togglebutton-primary-hover-color)
}

.amplify-togglebutton[data-variation=primary]:disabled {
    background-color: var(--amplify-components-togglebutton-primary-disabled-background-color);
    color: var(--amplify-components-togglebutton-primary-disabled-color)
}

.amplify-togglebutton[data-variation=primary][aria-pressed=true] {
    background-color: var(--amplify-components-togglebutton-primary-pressed-background-color);
    border-color: var(--amplify-components-togglebutton-primary-pressed-border-color);
    color: var(--amplify-components-togglebutton-primary-pressed-color)
}

.amplify-togglebutton[data-variation=primary][aria-pressed=true]:focus {
    border-color: var(--amplify-components-togglebutton-primary-pressed-focus-border-color);
    background-color: var(--amplify-components-togglebutton-primary-pressed-focus-background-color)
}

.amplify-togglebutton[data-variation=primary][aria-pressed=true]:hover:not(:disabled) {
    border-color: var(--amplify-components-togglebutton-primary-pressed-hover-border-color);
    background-color: var(--amplify-components-togglebutton-primary-pressed-hover-background-color)
}

.amplify-togglebutton[data-variation=link] {
    color: var(--amplify-components-togglebutton-link-color)
}

.amplify-togglebutton[data-variation=link]:hover {
    background-color: var(--amplify-components-togglebutton-link-hover-background-color);
    color: var(--amplify-components-togglebutton-link-hover-color)
}

.amplify-togglebutton[data-variation=link]:focus {
    background-color: var(--amplify-components-togglebutton-link-focus-background-color);
    color: var(--amplify-components-togglebutton-link-focus-color)
}

.amplify-togglebutton[data-variation=link]:disabled {
    color: var(--amplify-components-togglebutton-link-disabled-color)
}

.amplify-togglebutton[data-variation=link][aria-pressed=true] {
    background-color: var(--amplify-components-togglebutton-link-pressed-background-color);
    color: var(--amplify-components-togglebutton-link-pressed-color)
}

.amplify-togglebutton[data-variation=link][aria-pressed=true]:hover {
    background-color: var(--amplify-components-togglebutton-link-pressed-hover-background-color)
}

.amplify-togglebuttongroup {
    align-items: var(--amplify-components-togglebuttongroup-align-items);
    align-content: var(--amplify-components-togglebuttongroup-align-content);
    justify-content: var(--amplify-components-togglebuttongroup-justify-content);
    gap: 0
}

.amplify-togglebuttongroup .amplify-togglebutton:not(:first-of-type) {
    border-start-start-radius: 0;
    border-end-start-radius: 0
}

@supports not (border-start-start-radius: 0) {
    .amplify-togglebuttongroup .amplify-togglebutton:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
}

.amplify-togglebuttongroup .amplify-togglebutton:not(:first-of-type):not(:focus) {
    -webkit-border-start: 1px solid transparent;
    border-inline-start: 1px solid transparent
}

.amplify-togglebuttongroup .amplify-togglebutton:not(:last-of-type) {
    border-start-end-radius: 0;
    border-end-end-radius: 0
}

@supports not (border-end-end-radius: 0) {
    .amplify-togglebuttongroup .amplify-togglebutton:not(:last-of-type) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0
    }
}

.amplify-visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}


