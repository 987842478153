.container {
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--dark-table-head);
  border: 1rem solid var(--dark-modal-background);
  border-radius: 1rem;
}
.container h1,
div,
a,
a:hover,
strong {
  color: var(--dark-text-color);
}
.container p,
li,
div {
  text-align: left;
}
.headerImage {
  height: 8rem;
  margin-bottom: 1.5rem;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.modelTitle {
  font-size: 1.2rem;
  margin-bottom: 3rem;
}
.content {
  font-size: 1.1rem;
}
.modelName {
  border: 1px solid var(--dark-border-color);
  border-radius: 1rem;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.modelName p {
  text-align: center;
}
.modelName span {
  cursor: pointer;
  font-size: 1.2rem;
}
.modelImage {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  background-image: url(../../assets/login-page/lol-de9e79c5-2e9b-4742-9b7b-afa97c21f881.jpg);
}
.examples {
  padding-left: 1rem;
  padding-top: 1rem;
}
.usecases {
  padding-left: 1rem;
  padding-top: 1rem;
}
.tryForm {
  margin-left: 1rem;
  margin-right: 1rem;
}
.tryForm textarea {
  background-color: transparent;
  color: var(--dark-text-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 5rem !important;
}
.tryForm textarea::placeholder {
  color: var(--dark-text-color);
}
.tryFormButton button {
  background-color: var(--dark-modal-background);
  color: var(--dark-text-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.tryFormButton button:hover {
  color: var(--dark-neon-color-1);
}
.tryFormContent h3 {
  color: var(--dark-text-color);
}
.tryFormContent code {
  display: inline-block;
  width: 100%;
  min-height: 3rem;
  background-color: transparent;
  color: var(--dark-text-color);
}
.actions {
  width: 100%;
}
.actions button {
  width: 100%;
  height: 100%;
  background-color: var(--dark-modal-background);
  color: var(--dark-text-color);
  font-size: 1.1rem;
  border: none;
  border: 1rem solid var(--dark-background-histogram);
  border-radius: 1rem;
}
.actions a {
  width: 100%;
  height: 100%;
}
.actions button:hover {
  color: var(--dark-neon-color-1);
  border: 1rem solid var(--dark-background-histogram2);
}
@media (min-width: 1100px) {
  .container {
    left: 25rem;
    position: absolute;
    width: calc(100% - 50rem);
    margin-top: 3rem;
    height: calc(100% - 6rem);
    padding: 5rem;
  }
  .markdown {
    max-width: 50vw;
  }
}
@media (min-width: 481px) and (max-width: 1100px) {
  .markdown {
    max-width: 50vw;
  }
}
/* Markdown */
.markdown h1 {
  color: var(--dark-text-color);
  font-size: 2rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-shadow: none;
}
.markdown h2 {
  color: var(--dark-text-color);
  font-size: 1.8rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-shadow: none;
}
.markdown h3 {
  color: var(--dark-text-color);
  font-size: 1.6rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-shadow: none;
}
.markdown h4 {
  color: var(--dark-text-color);
  font-size: 1.4rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-shadow: none;
}
.markdown h5 {
  color: var(--dark-text-color);
  font-size: 1.2rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-shadow: none;
}
.markdown h6 {
  color: var(--dark-text-color);
  font-size: 1rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-shadow: none;
}
.markdown p {
  color: var(--dark-text-color);
  /* Warm Light Salmon for Text */
  margin: 0.2em 0;
}
.markdown ul,
.markdown ol {
  margin: 0.5em 0;
  padding-left: 1em;
}
.markdown li {
  color: var(--dark-text-color);
  /* Warm Tomato for List Items */
}
.markdown blockquote {
  border-left: 4px solid #ff4500;
  /* Warm Orange-Red for Blockquote Border */
  padding-left: 1em;
  color: #ff8c00;
  /* Warm Dark Orange for Blockquote Text */
}
.markdown code {
  background-color: #382f4f;
  /* Dark Slate Gray for Inline Code */
  padding: 0.2em 0.4em;
  border-radius: 3px;
  color: #ffa07a;
  /* Warm Light Salmon for Inline Code Text */
}
.markdown pre {
  background-color: #382f4f;
  /* Dark Slate Gray for Code Blocks */
  padding: 1em;
  border-radius: 4px;
  color: #ffa07a;
  /* Warm Light Salmon for Code Blocks Text */
}
.markdown a {
  color: var(--dark-text-color);
  /* Warm Gold for Links */
  text-decoration: none;
}
.markdown a:hover {
  text-decoration: underline;
  color: #ff6347;
  /* Warm Tomato for Hovered Links */
}
.markdown img {
  max-width: 100%;
  height: auto;
}
