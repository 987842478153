.container {
    height: 80%;
    overflow-y: auto;
}

.contentHeading {
    text-align: left;
    width: 100%;
    font-size: 1rem;
}

.contentHeading h2 {
    color: var(--dark-text-color);
}

.form {
    width: 100%;
    font-size: 1.1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.textInput {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5rem;

    background-color: var(--dark-table-head);
    color: var(--dark-text-color);
    border: none;
}

.numberInput {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5rem;

    background-color: var(--dark-table-head);
    color: var(--dark-text-color);
    border: none;
}

.checkboxInput {
    margin-right: 0.5rem;

    background-color: var(--dark-table-head);
    color: var(--dark-text-color);
    accent-color: var(--dark-neon-color-2);
    border: none;
    height: 30px;
}

.selectInput {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5rem;

    background-color: var(--dark-table-head);
    color: var(--dark-text-color);
    border: none;
    width: 100%;
}

.formElement {
    width: 80%;
}

.formElement input {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    font-size: 1.3rem;
}

.formElement label {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    align-items: center;
    cursor: pointer;
    text-decoration: underline;
}

.submitButton {
    width: 100%;
    background-color: var(--dark-modal-background);
    color: var(--dark-text-color);
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

@media (min-width: 1100px) {
    .container {
        left: 25rem;
        position: absolute;
        width: calc(100% - 50rem);
        margin-top: 3rem;
        height: calc(100% - 6rem);
    }
}

@media (min-width: 481px) and (max-width: 1100px) {
}

@media (max-width: 481px) {
}

/* Markdown */
.markdown h1 {
    color: var(--dark-text-color);
    font-size: 2rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-shadow: none;
}

.markdown h2 {
    color: var(--dark-text-color);
    font-size: 1.8rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-shadow: none;
}

.markdown h3 {
    color: var(--dark-text-color);
    font-size: 1.6rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-shadow: none;
}

.markdown h4 {
    color: var(--dark-text-color);
    font-size: 1.4rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-shadow: none;
}

.markdown h5 {
    color: var(--dark-text-color);
    font-size: 1.2rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-shadow: none;
}

.markdown h6 {
    color: var(--dark-text-color);
    font-size: 1rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-shadow: none;
}

.markdown p {
    color: var(--dark-text-color);
    /* Warm Light Salmon for Text */
    margin: 0.2em 0;
}

.markdown ul,
.markdown ol {
    margin: 0.5em 0;
    padding-left: 1em;
}

.markdown li {
    color: var(--dark-text-color);
    /* Warm Tomato for List Items */
}

.markdown blockquote {
    border-left: 4px solid #ff4500;
    /* Warm Orange-Red for Blockquote Border */
    padding-left: 1em;
    color: #ff8c00;
    /* Warm Dark Orange for Blockquote Text */
}

.markdown code {
    background-color: #382f4f;
    /* Dark Slate Gray for Inline Code */
    padding: 0.2em 0.4em;
    border-radius: 3px;
    color: #ffa07a;
    /* Warm Light Salmon for Inline Code Text */
}

.markdown pre {
    background-color: #382f4f;
    /* Dark Slate Gray for Code Blocks */
    padding: 1em;
    border-radius: 4px;
    color: #ffa07a;
    /* Warm Light Salmon for Code Blocks Text */
}

.markdown a {
    color: var(--dark-text-color);
    /* Warm Gold for Links */
    text-decoration: none;
}

.markdown a:hover {
    text-decoration: underline;
    color: #ff6347;
    /* Warm Tomato for Hovered Links */
}

.markdown img {
    max-width: 100%;
    height: auto;
}
